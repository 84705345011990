<template>
  <div class="home-data">
    <div class="--header">
      <div
           class="gradient-header show-desktop"
           :style="`background-image: url(${require('../assets/images/test_swirls_01_wALPHA_10.png')});`">
      </div>

      <div class="-menu-back show-desktop">
        <div class="back-button--container clickable" @click="$router.push({ name: 'HomeData' })">
          <iconComponent symbol="arrowLeft" :width="30" />
        </div>

        <h1>Privacy Policy – Visitors</h1>
      </div>

      <div class="-menu-back show-mobile-and-tablet">
        <div class="back-button--container clickable" @click="$router.push({ name: 'HomeData' })">
          <iconComponent symbol="arrowLeft" :width="30" />
        </div>

        <h3>Privacy Policy – Visitors</h3>
      </div>
    </div>

    <div class="data-wrapper">
      <div class="left-card">
        <div class="data-text">
          <div>
            <h3>Privacy policy – when you visit the Enginio platform</h3>
          </div>
          <div>
            When you visit the Enginio platform, we at NRG Nordic Retail Group AB (”<b>NRG</b>”) and the company which site at Enginio you visit (the “<b>Company</b>“) will process your personal data.
            NRG and the Company (“<b>we</b>” or “<b>us</b>”) care about your privacy and want you to feel safe when you use Enginio and we process your personal data. 
          </div>
          <div>
            <b>In short: Your personal data – for what purposes do we use it?</b>
          </div>
          <div>
            • Improve Enginio and the activities on Enginio by the use of <a href="https://www.google.com/analytics/" target="_blank">Google Analytics</a>, and <br>
            • Show you relevant marketing on other sites, e.g. on <a href="https://www.facebook.com/policy/cookies/" target="_blank">Facebook</a>. 
          </div>
          <div>
            To protect your privacy, we have taken measures to avoid identifying you when you visit Enginio.
            For instance, we only store an encrypted version of your IP-address to reduce the risk of being able to identify you.
            We will gather personal data by using cookies.
            How we do this is described in our text about cookies which you find <a :href="`${windowOrigin}/data/cookies`">here</a>.
          </div>
          <div>
            Don’t hesitate to contact us at support@enginio.io with any questions you have regarding this policy!
          </div>
          <div>
            <b>Below you can read more about:</b>
          </div>
          <div>
            Who is responsible for the processing of your personal data?<br>
            Detailed description on how we process your personal data<br>
            Who can gain access to your personal data and why?<br>
            Where are your personal data processed?<br>
            What are your rights when we process your personal data?
          </div>
          <div>
            <b>Who is responsible for the processing of your personal data?</b>
          </div>
          <div>
            NRG Nordic Retail Group AB (Swedish registration number 556608-5642) and the Company are jointly responsible for the processing of your personal data when you visit Enginio platform. 
            Should you have any questions regarding our processing of your personal data, or if you wish to exercise any of your rights under data protection legislation, please feel free to contact the Company or NRG.
            You can reach NRG at info@nordicretailgroup.com or Linnégatan 9-11 114 47, Stockholm.
          </div>
          <div>
            <b>Detailed description on how we process your personal data</b>
          </div>
          <div>
            When you visit Enginio we will gather your personal data from your device and from the analytics- and marketing companies we cooperate with (see below).
            Such companies will also use information they have about you since before.
          </div>
          <div class="a-terms-table">
            <div class="table-header">
              To improve Enginio and activities on Enginio
            </div>
            <div class="table-header2">
              What processing we perform
            </div>
            <div class="table-body">
              • Gather information that is necessary in order to improve Enginio and activities on Enginio. To do this we use the analytic service from <a href="https://www.google.com/analytics/" target="_blank">Google Analytics</a><br>
              The analytic service means that <a href="https://www.google.com/policy/privacy/partners/" target="_blank">Google</a> place a random ID on your device to distinguish your device from other visitors and to acknowledge patterns in how Enginio is used. We will however not know who you are<br>
              The personal data we process will be used e.g. to optimize functions and to adapt Enginio to suit the visitors
            </div>
            <div class="table-header2">
              What personal data we process
            </div>
            <div class="table-body">
              • An encrypted version of your IP- address which we cannot connect to you as an individual<br>
              • Information about your device/browser (which area in the country you use Enginio from and which screen resolution you have)<br>
              • Information about your activities on Enginio<br>
              • Other information <a href="https://www.google.com/policy/privacy/partners/" target="_blank">Google</a> have about you, e.g. information about from which site you found us 
            </div>
            <div class="table-header2">
              Our lawful basis for the processing
            </div>
            <div class="table-body">
              Consent<br>
              The personal data will be processed based on your consent. You can withdraw such consent at any time.<br>
              You can prevent <a href="https://www.google.com/analytics/" target="_blank">Google Analytics</a> to use your personal data by downloading and install this this browser add on.
            </div>
          </div>
          <div>
            <b>Storage period:</b> We will store your personal data for as long as necessary to perform our analysis and further improve Enginio.
            <a href="https://www.google.com/policy/privacy/partners/" target="_blank">Google</a> will continue to store your personal data for it's own purposes and Google will inform you separately about such storing. 
          </div>
          <div class="a-terms-table">
            <div class="table-header">
              To show you and your friends relevant marketing from us
            </div>
            <div class="table-header2">
              What processing we perform
            </div>
            <div class="table-body">
              • Show you relevant offers and marketing on other sites as <a href="https://www.facebook.com/policy/cookies/" target="_blank">Facebook</a> (including Instagram), TikTok and Schibsted’s sites. You see marketing based on information that these companies have about you beforehand (so called profiling*) <br>
              • Share information about you with these companies (e.g<a href="https://www.facebook.com/policy/cookies/" target="_blank">Facebook</a> so they will have information about which type of visitors that are interested in us 
            </div>
            <div class="table-header2">
              What personal data we process
            </div>
            <div class="table-body">
              • An encrypted version of your IP- address which we cannot connect to you as an individual
              After your visit to our site you will see search results and ads based on: <br>
              • An analysis of how you use Enginio <br>
              • Information that the marketing services we use have about you since before, e.g. information about from which site you found us
            </div>
            <div class="table-header2">
              Our lawful basis for the processing
            </div>
            <div class="table-body">
              Consent<br>
              The personal data will be processed based on your consent. You can withdraw such consent at any time.<br>
              Here on <a href="https://www.facebook.com/policy/cookies/" target="_blank">Facebook</a> (including Instagram), TikTok and Schibsted d will continue to process your personal data as independently responsible (data controllers). Information on how long they store your personal data is found in their respective privacy information texts.
            </div>
          </div>
          <div>
            Storage period: You will continue to see our marketing for as long as we have reason to believe that you are interested in marketing from us.<br>
            <a href="https://www.google.com/policy/privacy/partners/" target="_blank">Google</a> and <a href="https://www.facebook.com/policy/cookies/" target="_blank">Facebook</a> will continue to process your personal data as independently responsible. Information on how long they store your personal data is found in their respective information texts. <br>
          </div>
          <div>
            <b>* Profiling:</b> We use so called profiling to be able to show you offers that are relevant to you and to provide you with customised marketing.
            We use profiling because without it, you would see offers and information which you probably not are interested in.
            You have the right to object to profiling as described below under the section explaining your rights.
          </div>
          <div>
            <b>Who can gain access to your personal data and why?</b>
          </div>
          <div>
            Your personal data is initially collected and processed by us and we do not sell your personal data. This means that your personal data will be handled by our employees, but only personnel who need such access to conduct their work. 
          </div>
          <div>
            If you have provided your consent, we will share your personal data with: <br>
            • <a href="https://www.google.com/policy/privacy/partners/" target="_blank">Google</a>, which provide the service to analyse Enginio, and <br>
            • The companies which provide the marketing service we use, i.e. <a href="https://www.facebook.com/policy/cookies/" target="_blank">Facebook</a> (including Instagram), TikTok and Schibsted.
          </div>
          <div>
            Furthermore, to conduct our business in the most efficient way we will share your personal data within the <b>company group</b>.
          </div>
          <div>
            If you have any questions regarding how we share your personal data or want to know more about who we share your personal data with, please feel free to contact us.
          </div>
          <div>
            <b>Where are your personal data processed?</b>
          </div>
          <div>
            Your personal data risk being transferred outside of the EU/EEA, such as to the United States if <a href="https://www.google.com/policy/privacy/partners/" target="_blank">Google</a> and <a href="https://www.facebook.com/policy/cookies/" target="_blank">Facebook</a> process personal data there and to China if TikTok process use personal data there. We have anonymised your personal data as far as possible to avoid your personal data being transferred outside the EU/EEA. 
          </div>
          <div>
            We and our suppliers transfer your personal data based on an adequacy decision or rely on Standard Contractual Clauses for the transfer of personal data outside of the EU/EEA. The use of Standard Contractual Clauses is an effort to provide a safe transfer of your personal data.
          </div>
          <div>
            If you want to know more about where your personal data will be processed, please feel free to contact us. Our contact information can be found at the beginning of this privacy policy.
          </div>
          <div>
            <b>What are your rights when we process your personal data?</b>
          </div>
          <div>
            You have certain rights that you can exercise to affect how we process your personal data. You can read about what those rights are below. Contact us if you want to know more about your rights or exercise any of your rights. 
          </div>
          <div>
            • <b>Right to lodge a complaint with a supervisory authority</b><br>
            You always have the right to lodge a complaint with a supervisory authority. You may do this in the EU/EEA member state where you live, work or where an infringement of applicable data protection laws is alleged to have occurred. <br>
            • The supervisory authority in Sweden is the <a href="https://www.imy.se/en/" target="_blank">Swedish Data Protection Authority</a> (Integritetsskyddsmyndigheten, “IMY”). <br>
            • The supervisory authority in Denmark is the <a href="https://www.datatilsynet.dk/english" target="_blank">Danish Data Protection Agency</a> (Datatilsynet). <br>
            • The supervisory authority in Norway is the <a href="https://www.datatilsynet.no/en/" target="_blank">Norwegian Data Protection Authority</a> (Datatilsynet).<br>
            • The supervisory authority in Finland is the <a href="https://tietosuoja.fi/en/home" target="_blank">Office of the Data Protection Ombudsman</a> (Tietosuojavaltuutetun toimisto).<br>
          </div>
          <div>
            • <b>Right to withdraw your consent and object to processing</b><br>
            You have a right to withdraw any consent you have given us, partly or completely. <br>
            You always have a right to object to our processing of your personal data when the processing is performed for marketing and profiling purposes.<br>
          </div>
          <div>
            • <b>Right to information and access</b><br>
            You have the right to obtain confirmation as to whether we are processing personal data about you or not. If we do process your personal data, you also have a right to obtain a copy of the personal data processed by us as well as information about how we process your personal data. <br>
          </div>
          <div>
            • <b>Right to rectification</b><br>
            You have a right to correct any inaccurate personal data concerning you that we may be processing and to ask us to have incomplete personal data completed. <br>
          </div>
          <div>
            • <b>Right to erasure (“the right to be forgotten”) and restriction of processing</b><br>
            You have a right to request that we delete your personal data. We will delete the personal data for example if you have withdrawn your consent and there is no other lawful basis for processing. <br>
            You also have a right to request that we restrict our processing of your personal data. We will do so for example when the accuracy of the personal data is contested by you, or the processing is unlawful, and you do not want us to delete your personal data but instead you request that we restrict our use of them.<br>
          </div>
          <div>
            • <b>Right to data portability</b><br>
            Under certain circumstances, you have a right to receive your personal data from us in a structured, commonly used and machine-readable format and, where technically feasible, have your personal data transferred to another company (“data portability”). This applies to personal data that you have provided to us in a structured, commonly used and machine-readable format, if our processing of your personal data is carried out by automated means and the lawful basis for our processing is your consent. 
          </div>
          <div class="a-line"></div>
          <div>
            This privacy policy was adopted by us on 2022-03-01.
          </div>
        </div>
      </div>

      <div class="right-card">
        <div class="--container">
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.cookies' })">
            <div class="button-text">Cookies policy</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link unclickable">
            <div class="button-text">Privacy Policy - Visitors</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.members' })">
            <div class="button-text">Privacy Policy - Members</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.participants' })">
            <div class="button-text">Privacy Policy - Participants</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.terms' })">
            <div class="button-text">Terms and conditions</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <!--<div
             class="data-link"
             @click="$router.push({ name: 'HomeData.companies' })">
          Companies at Enginio
          <iconComponent symbol="forward" />
        </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'HomeDataVisitors',
  components: {},
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    this.setPageTitle('Privacy Policy – Visitors');
    this.setCompany({});
  },
  computed: {
    ...mapMutations({
      setCompany: 'COMPANY',
    }),
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/scss/pages/HomeDataVisitors";
</style>
