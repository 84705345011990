<template>
  <div class="offer-preview entity-preview">
    <div class="preview-width-picker">
      <h3>Preview of Offer "{{ offer.first_header }}"</h3>
      <md-button
        class="md-icon-button close-preview-button"
        @click="previewWidth = 'computer'">
        <iconComponent symbol="computer"/>
      </md-button>
      <md-button
        class="md-icon-button close-preview-button"
        @click="previewWidth = 'tablet'">
        <iconComponent symbol="tablet"/>
      </md-button>
      <md-button
        class="md-icon-button close-preview-button"
        @click="previewWidth = 'smartphone'">
        <iconComponent symbol="smartphone"/>
      </md-button>
      <md-button
        class="md-icon-button close-preview-button"
        @click="$emit('close')">
        <iconComponent symbol="cross"/>
      </md-button>
    </div>
    <div
      v-if="offer && goodiebag && (expiresAtState !== 'expired' || expiredOffer || offer.closed)"
      class="preview-default"
      :class="{
        'preview-tablet': previewWidth === 'tablet',
        'preview-smartphone': previewWidth === 'smartphone',
      }">
      <div class="user-offer">
        <div class="main-offer">
          <div
            v-if="offer.media && offer.media.length === 1 && offer.media[0].fileType.includes('image')"
          class="offer-header-image offer-single-image">
          <img :src="offer.media[0].url" alt="">
            <div v-if="offer.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                avslutad
              </md-chip>
            </div>
            <img
              v-if="offer.icon?.url || goodiebag.icon?.url"
              :src="`${!offer.unique_icon && goodiebag.icon?.url ? goodiebag.icon?.url : offer.icon?.url}`"/>
          </div>
          <video
            v-else-if="offer.media && offer.media.length === 1 && offer.media[0].fileType.includes('video')"
            class="news-autoplay offer-header-image autoplayMedia"
            controls
            muted playsinline>
            <div v-if="offer.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                avslutad
              </md-chip>
            </div>
            <source
              :src="`${offer.media && offer.media[0] ? offer.media[0].url : '' }`"
              :type="offer.media[0].fileType">
            {{ transl.Offer.novideo }}
          </video>
          <div
            v-else-if="offer.media && offer.media.length > 1"
            class="offer-header-image carousel">
            <aCarousel
              :media-list="offer.media"
              :single-image="true"/>
            <img
              v-if="offer.icon?.url || goodiebag.icon?.url"
              :src="`${!offer.unique_icon && goodiebag.icon?.url ? goodiebag.icon?.url : offer.icon?.url}`"/>
            <div v-if="offer.closed" class="card-tag closed-card-chip">
              <md-chip class="card-chip">
                avslutad
              </md-chip>
            </div>
          </div>
          <iframe
            v-else-if="offer.iframe_media && offer.iframe_media.length"
            class="offer-header-image offer-header-iframe"
            :src="`https://player.vimeo.com/video/${offer.iframe_media[0].id}`" frameborder="0" allow="autoplay; fullscreen" allowfullscreen>
          </iframe>

          <div class="offer-content-wrapper content-page">
            <div class="offer-fast-icons">
              <div
                v-if="offer.variations"
                class="offer-fast-text">
                <iconComponent symbol="style"/>
                <span>Fler varianter</span>
              </div>
              <div
                v-else-if="offer.save_text"
                class="offer-fast-text">
                <iconComponent symbol="newReleases"/>
                <span>{{ offer.save_text }}</span>
              </div>
              <div
                v-else
                class="offer-fast-text">
                <span style="padding: 0;">{{ offer.quick_text }}</span>
              </div>
              <div class="offer-fast-icons-actions">
                <div class="btn-icon-text btn-description offer-fast-share"
                  v-if="!offer.closed">
                  <iconComponent symbol="heart"/>
                  <div class="md-description">Spara</div>
                </div>
                <div
                  class="btn-icon-text btn-description offer-fast-share"
                  v-if="offer.share && !offer.closed"
                  v-clipboard:copy="currentUrl"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError">
                  <iconComponent symbol="share"/>
                  <div class="md-description">Dela</div>
                </div>
                <!--<input
                  id="share-link"
                  :value="currentUrl"
                  type="hidden">-->
              </div>
            </div>
            <div class="offer-header">{{ offer.first_header }}</div>
            <div
              class="offer-header-two"
              style="margin-bottom: 1.5rem;">
              <span v-html="offer.second_header"></span>
            </div>

            <div
              v-if="offer.clearon_id"
              class="offer-header-italic"
              style="margin-bottom: 1rem;">
              Rabatten gäller på City Gross, Coop, Hemköp och Willys.
            </div>

            <md-button
              v-if="offer.external_link && !expiredOffer"
              class="md-secondary">
              {{ offer.link_button_title || 'Till Erbjudandet' }}
            </md-button>

            <md-button
              v-else-if="offer.closed"
              class="md-secondary">
              Tillbaka till {{ goodiebag.offers && goodiebag.offers.length === 1 ? 'startsidan' : 'goodiebagen' }}
            </md-button>

            <md-button
              v-else
              class="md-secondary">
              {{ offer.link_button_title || 'Till Erbjudandet' }}
            </md-button>
          </div>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="internalLinkModal" @click="internalLinkModal = false"></div>
          </transition>
          <transition name="slide" appear>
            <div class="modal" v-if="internalLinkModal">
              <div v-if="offer.closed">
                <div class="competition-info">
                  <div class="internal-link-title-2">
                    Erbjudandet har gått ut
                  </div>
                  <div class="internal-link-title-4" style="text-align: center;">
                    Håll utkik i se.enginio.io för att se när nya liknande erbjudanden ramlar in!
                  </div>
                  <md-button
                    style="width: 80%; color: white; background-color: black; border-radius: 6px;">
                    Tillbaka till {{ goodiebag.offers && goodiebag.offers.length === 1 ? 'startsidan' : 'goodiebagen' }}
                  </md-button>
                </div>
              </div>
              <div v-else>
                <div v-if="!expiredOffer">
                  <div class="internal-link-header offer-header">
                    {{ offer.first_header }}
                  </div>
                </div>

                <div class="competition-info">
                  <iconComponent symbol="gift"/>

                  <div class="internal-link-title-2">
                    Hoppsan!
                  </div>
                  <div class="internal-link-title-4" style="text-align: center;">
                    Du måste logga in för att ta del av erbjudandet
                    <div v-if="!isMobile && offer.clearon_id">
                      Vi ser att du använder datorn, rabattkoden kan bara nås från en mobil enhet
                    </div>
                  </div>
                  <div
                    v-if="!offer.winner_adress && !offer.recurring_time"
                    class="internal-link-title-1"
                    style="padding: 1rem;">
                    Gå in på se.enginio.io i din mobil när du är i butik redo för att aktivera erbjudandet!
                  </div>
                  <md-button
                    style="width: 80%; color: white; background-color: black; border-radius: 8px!important;">
                    Logga in
                  </md-button>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div
          class="sideoffers-wrapper"
          v-if="sideOffers && sideOffers.length">
          <div
            style="text-align: left; font-weight: 600;"
            class="offer-box">
            Fler aktiveringar
            <br/>
            från {{ goodiebag.company.name }}
          </div>
          <div
            class="offer-box"
            :class="{ 'no-text-box': sideOffer.hide_text_box }"
            v-for="sideOffer in sideOffers"
            :key="sideOffer._id">
            <div
              v-if="sideOffer.thumbnail && sideOffer.thumbnail.fileType && sideOffer.thumbnail.fileType.includes('image')"
              class="offer-pic"
              :style="`background-image: url(${sideOffer.thumbnail.url});`">
            </div>
            <div
              v-else-if="sideOffer.media && sideOffer.media[0] && sideOffer.media[0].fileType.includes('image')"
              class="offer-pic"
              :style="`background-image: url(${sideOffer.media && sideOffer.media[0] ? sideOffer.media[0].url : '' });`">
            </div>
            <video
              v-else-if="sideOffer.media && sideOffer.media[0] && sideOffer.media[0].fileType.includes('video')"
              class="news-autoplay autoplayMedia"
              :style="!sideOffer.hide_text_box ? 'border-radius: 6px 6px 0 0;' : 'border-radius: 6px;'"
              :controls="false"
              muted playsinline>
              <source
                :src="`${sideOffer.media && sideOffer.media[0] ? sideOffer.media[0].url : '' }`"
                :type="sideOffer.media[0].fileType">
              {{ transl.Offer.novideo }}
            </video>
            <img
              v-if="sideOffer.icon?.url && sideOffer.unique_icon"
              :src="`${sideOffer.icon?.url}`"/>
            <div class="card-tag">
              <md-chip class="card-chip" v-if="sideOffer.offer_tag">
              {{ transl.tags[sideOffer.offer_tag] }}
              </md-chip>
            </div>

            <div
              v-if="!sideOffer.hide_text_box"
              class="offer-box-text">
              <div class="offer-box-name body-1-bold">{{ sideOffer.first_header }}</div>
              <div
                v-if="sideOffer.variations"
                class="offer-box-info body-2">
                <iconComponent symbol="style"/>
                <span>Fler varianter</span>
              </div>
              <div
                v-else-if="sideOffer.save_text"
                class="offer-box-info body-2">
                <iconComponent symbol="newReleases"/>
                <span>{{ sideOffer.save_text }}</span>
              </div>
              <div
                v-else
                class="offer-box-info body-2">
                <span style="padding: 0;">{{ sideOffer.quick_text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExpiredMixin from '../mixins/ExpiredMixin.vue';
import aCarousel from '../components/ACarousel.vue';

export default {
  name: 'PreviewOffer',
  props: {
    offer: {
      type: Object,
      required: true,
    },
    goodiebag: {
      type: Object,
      required: true,
    },
  },
  components: {
    aCarousel,
  },
  mixins: [ExpiredMixin],
  mounted() {
    this.expiresAtState = this.getExpiredState(this.offer);
  },
  computed: {
    sideOffers() {
      if (this.goodiebag.offers && this.goodiebag.offers.length) {
        let el = this;
        const excludeCurrentOffer = this.goodiebag.offers.filter(function (offer) {
          return offer._id !== el.offer._id && !offer.closed && offer.clickable;
        });
        let indexes = [0,1,2];
        if (excludeCurrentOffer.length > 3) {
          indexes = [];
          while (indexes.length < 3) {
            let r = Math.floor(Math.random() * excludeCurrentOffer.length-1) + 1;
            if (indexes.indexOf(r) === -1) {
              indexes.push(r);
            }
          }
        }
        const sideOffers = excludeCurrentOffer.filter(function (offer, index) {
          return indexes.indexOf(index) !== -1;
        });
        return this.shuffle(sideOffers);
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      currentUrl: location.href,
      internalLinkModal: false,
      firstTime: true,
      expiredOffer: false,
      expiresAtState: '',
      previewWidth: 'computer',
    };
  },
  methods: {
    shuffle(a) {
      let j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    },
    onCopy() {
      this.setAlert('Kopierade till urklipp');
    },
    onError() {
      this.setAlert('Kunde inte kopiera till urklipp');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/HomeOffer";
</style>
