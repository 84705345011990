/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import usersApi from '../../api/users.api';

// initial state
const state = {
  user: {},
  users: [],
  modalUsers: [],
  usersStatus: '',
};

// getters
const getters = {
  user: state => state.user,
  users: state => state.users,
  modalUsers: state => state.modalUsers,
  usersStatus: state => state.usersStatus,
};

// actions
const actions = {
  findAdminUsers({ commit }, payload) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.findAdminUsers(payload)
      .then((response) => {
        commit('USERS', response.users);
        commit('USERS_STATUS', 'FINISHED');
      });
  },

  findAdminModalUser({ commit }, payload) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.findAdminUsers(payload)
      .then((response) => {
        commit('USERS', response.users);
        commit('USERS_STATUS', 'FINISHED');
      });
  },

  getAdminUser({ commit }, payload) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.getAdminUser(payload)
      .then((response) => {
        commit('USER', response.user);
        commit('USERS_STATUS', 'FINISHED');
      });
  },

  adminDeleteUser({ commit }, payload) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.adminDeleteUser(payload.userId)
      .then(() => {
        commit('DELETE_USER', payload.userId);
        commit('USERS_STATUS', 'FINISHED');
        return;
      });
  },

  deleteLostUsers({ commit }) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.deleteLostUsers()
      .then(({ deleted }) => {
        commit('USERS_STATUS', 'FINISHED');
        return deleted;
      });
  },

  saveAdminPartnerToken({ commit }, payload) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.saveAdminPartnerToken(payload.partnerName)
      .then(({ message }) => {
        commit('USERS_STATUS', 'FINISHED');
        return message;
      });
  },

  testWS({ commit }) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.testWS()
      .then(({ message }) => {
        commit('USERS_STATUS', 'FINISHED');
        return message;
      });
  },

  executeEligbleActions({ commit }) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.executeEligbleActions()
      .then(({ message }) => {
        commit('USERS_STATUS', 'FINISHED');
        return message;
      });
  },

  adminDeleteUserOffer({ commit }, payload) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.adminDeleteUserOffer(payload)
      .then((response) => {
        commit('SAVE_USER', response.user);
        commit('USER', response.user);
        commit('USERS_STATUS', 'FINISHED');
      });
  },

  adminAddUserRole({ commit }, payload) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.adminAddUserRole(payload)
      .then((response) => {
        commit('SAVE_USER', response.user);
        commit('USER', response.user);
        commit('USERS_STATUS', 'FINISHED');
      });
  },

  adminRemoveUserRole({ commit }, payload) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.adminRemoveUserRole(payload.userId, payload.role)
      .then((response) => {
        commit('SAVE_USER', response.user);
        commit('USER', response.user);
        commit('USERS_STATUS', 'FINISHED');
      });
  },

  deleteAdminRemovedUsers({ commit }) {
    commit('USERS_STATUS', 'LOADING');
    return usersApi.deleteAdminRemovedUsers()
      .then((response) => {
        commit('USERS_STATUS', 'FINISHED');
        return response;
      });
  },
};

// mutations
const mutations = {
  USER(state, user) {
    state.user = user;
  },
  USERS(state, users) {
    state.users = users;
    state.modalUsers = [];
    for (let i = 0; i < state.users.length; i += 1) {
      state.modalUsers.push({
        value: state.users[i]._id,
        text: `${state.users[i].name} ${state.users[i].email} ${state.users[i].phone_nr}`,
      });
    }
  },
  DELETE_USER(state, userId) {
    for (let i = 0; i < state.users.length; i += 1) {
      if (state.users[i]._id === userId) {
        state.users.splice(i, 1);
      }
    }
  },
  SAVE_USER(state, user) {
    for (let i = 0; i < state.users.length; i += 1) {
      if (state.users[i]._id === user._id) {
        Vue.set(state.users, i, user);
      }
    }
  },
  USERS_STATUS(state, status) {
    state.usersStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
