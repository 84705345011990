<template>
  <div v-if="loadedPage" class="economy-editor">
    <h2>Create Invoice/Offer</h2>
    <form @submit.prevent="submitForm" class="form-container">
      <!-- Campaign and Market Selection -->
      <div v-for="(campaign, index) in selectedCampaigns" :key="index" class="form-group">
        <label for="market">Select Market:</label>
        <select v-model="campaign.marketId" class="form-control">
          <option v-for="option in markets" :key="option.name" :value="option.name">
            {{ option.name }}
          </option>
        </select>

        <label for="campaign">Select Campaign:</label>
        <select v-model="campaign.name" class="form-control">
          <option v-for="option in campaigns" :key="option.name" :value="option.name">
            {{ option.name }}
          </option>
        </select>
        <input type="number" v-model="campaign.quantity" placeholder="Quantity" class="form-control" />
        
        <button type="button" @click="removeCampaign(index)" class="btn btn-danger">Remove</button>
      </div>
      <button type="button" @click="addCampaign" class="btn btn-primary">Add Campaign</button>

      <!-- Company Discounts -->
      <div v-for="(discount, index) in selectedDiscounts" :key="index" class="form-group">
        <label for="discount">Select Discount:</label>
        <select v-model="discount.name" class="form-control">
          <option v-for="option in discounts" :key="option.name" :value="option.name">
            {{ option.name }} - {{ option.value }}%
          </option>
        </select>
        <button type="button" @click="removeDiscount(index)" class="btn btn-danger">Remove</button>
      </div>
      <button type="button" @click="addDiscount" class="btn btn-primary">Add Discount</button>

      <!-- Transaction Types and Amount -->
      <div v-for="(transaction, index) in transactions" :key="index" class="form-group">
        <label for="transactionType">Transaction Type:</label>
        <input type="text" v-model="transaction.type" placeholder="Transaction Type" class="form-control" />
        <label for="amount">Amount:</label>
        <input type="number" v-model="transaction.amount" placeholder="Amount" class="form-control" />
        <button type="button" @click="removeTransaction(index)" class="btn btn-danger">Remove</button>
      </div>
      <button type="button" @click="addTransaction" class="btn btn-primary">Add Transaction</button>

      <button type="submit" class="btn btn-success">Submit</button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EconomyEditor',
  props: {
    companyId: {
      type: String,
      required: true
    },
  },
  mounted() {
    this.loadPage();
  },
  computed: {
    ...mapGetters([
      'company',
    ]),
  },
  watch: {
    companyId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    },
  },
  data() {
    return {
      loadedPage: true,
      campaigns: [
        { name: 'Cashback' },
        { name: 'Buy and get' },
        { name: 'Buy and try' },
        { name: 'Buy and review' },
        { name: 'Buy and unlock' },
        { name: 'Qr Hunt' },
        { name: 'Scratch' },
        { name: 'Loyalty shop' },
        { name: 'Spin the wheel' },
      ],
      markets: [
        { name: 'Sweden', db: 'SE' },
        { name: 'England', db: 'EN' },
        { name: 'Norway', db: 'NO' },
        { name: 'Denmark', db: 'DK' },
        { name: 'Finland', db: 'FI' },
        { name: 'Spain', db: 'ES' },
        { name: 'Italy', db: 'IT' },
        { name: 'Portugal', db: 'PT' },
        { name: 'France', db: 'FR' },
        { name: 'Belgium', db: 'BE' },
        { name: 'Netherlands', db: 'NL' },
        { name: 'Flanders', db: 'FL' },
        { name: 'Greece', db: 'GR' },
        { name: 'Poland', db: 'PL' },
        { name: 'Germany', db: 'DE' },
        { name: 'Czech Republic', db: 'CZ' },
        { name: 'Switzerland', db: 'CH' },
        { name: 'Turkey', db: 'TR' },
        { name: 'Croatia', db: 'HR' },
        { name: 'Slovenia', db: 'SI' },
        { name: 'Estonia', db: 'EE' },
        { name: 'Lithuania', db: 'LT' },
        { name: 'Latvia', db: 'LV' },
      ],
      discounts: [
        { name: 'Discount 1', value: 10 },
        { name: 'Discount 2', value: 20 },
        // Add more discounts as needed
      ],
      selectedCampaigns: [{ id: null, quantity: 1, marketId: null }],
      selectedMarkets: [{ id: null }],
      selectedDiscounts: [{ id: null }],
      transactions: [{ type: '', amount: 0 }],
    };
  },
  methods: {
    async loadPage() {
      this.loadedPage = false;
      await this.$store.dispatch('getAdminCompany', { entityId: this.companyId });
      this.setPageTitle('Admin', 'Economy');
      this.loadedPage = true;
    },
    addCampaign() {
      this.selectedCampaigns.push({ id: null, quantity: 1 });
    },
    removeCampaign(index) {
      this.selectedCampaigns.splice(index, 1);
    },
    addMarket() {
      this.selectedMarkets.push({ id: null });
    },
    removeMarket(index) {
      this.selectedMarkets.splice(index, 1);
    },
    addDiscount() {
      this.selectedDiscounts.push({ id: null });
    },
    removeDiscount(index) {
      this.selectedDiscounts.splice(index, 1);
    },
    addTransaction() {
      this.transactions.push({ type: '', amount: 0 });
    },
    removeTransaction(index) {
      this.transactions.splice(index, 1);
    },
    submitForm() {
      const invoiceData = {
        campaigns: this.selectedCampaigns,
        discounts: this.selectedDiscounts,
        transactions: this.transactions,
      };
      console.log('Invoice Data:', invoiceData);
      // Add logic to handle form submission, e.g., send data to the server
    },
  }
};
</script>

<style lang="scss" scoped>
.economy-editor {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-control {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.btn-success {
  background-color: #28a745;
  color: white;
}
</style>