<template>
  <div
    class="game-body"
    v-if="loadedPage"
    :style="cssProps">
    <div class="game-desktop-background"></div>
    <div v-if="step != 'finished'" class="game-content-background"></div>
    <span
      v-if="isDevelopment"
      class="dev-navigation">
      DEV
      <router-link class="btn" :to="{ name: 'QrHuntOnboarding' }">onboarding</router-link>
    </span>

    <div
      v-if="step == 'finished'"
      class="game-page-absolute">
      <div v-html="game.winner_text"></div>
      <div class="btn btn-activate"
        @click="ctaDoneRedirect()">
        {{ game.cta_done_button || transl.Games.back }}
      </div>
    </div>
    <div
      v-else
      class="game-page">
      <div
        v-if="step == 'tiebreaker'"
        class="tiebreaker-container">
        <div class="image-container">
          <div
            v-if="game.terms_text"
            class="info-icon-bottom-right"
            @click="tocModal = true"
            style="cursor: pointer;" >
            <iconComponent
              class="game-info-icon"
              symbol="info"
              width="18px"/>
          </div>
          <img class="tiebreaker-image" v-if="game.tiebreak_image" :src="game.tiebreak_image.url" alt="">
          <div
            v-if="game.map_image"
            class="map-icon-right"
            @click="mapModal = true"
            style="cursor: pointer;">
            <iconComponent
              class="game-info-icon"
              symbol="map"
              width="18px"/>
          </div>
        </div>

        <div class="tiebreaker-question">
          <div class="tiebreaker-text" v-html="game.tiebreak_text"></div>
          <Form
            :questions="[game.tiebreak_question]"
            :accentColor="game.accent_color"
            :textColor="game.text_color"
            :submitButton="game.tiebreak_button_text"
            @answeredAll="tiebreakAnswer = $event"
            @missingRequired="tiebreakAnswer = ''"
            @submit="participate()"/>

          <div class="winner-info" v-html="game.winner_text"></div>
        </div>
      </div>

      <div
        v-else-if="step == 'points'"
        class="points-container">
        <div class="image-container">
          <div
            v-if="game.terms_text"
            class="info-icon-bottom-right"
            @click="tocModal = true"
            style="cursor: pointer;" >
            <iconComponent
              class="game-info-icon"
              symbol="info"
              width="18px"/>
          </div>
          <img class="tiebreaker-image" v-if="game.hunt_image" :src="game.hunt_image.url" alt="">
          <div
            v-if="game.map_image"
            class="map-icon-right"
            @click="mapModal = true"
            style="cursor: pointer;">
            <iconComponent
              class="game-info-icon"
              symbol="map"
              width="18px"/>
          </div>
        </div>

        <div class="points-all-points">
          <div class="points-grid" v-if="participation && participation.points">
            <span class="scan-text">{{ game.hunt_header }}</span>
            <span class="points-text" :style="`color: ${game.accent_color};`"> {{ participation.points.length }}/{{ game.hunt_objectives.length }}</span>
          </div>
          <div
            class="btn"
            @click="scanModal = true">
            <iconComponent symbol="camera" fill="white" stroke="white"/>
            {{ transl.Games.opencamera }}
          </div>
          <div
            v-if="userObjectives.length"
            class="points-objectives">
            <div
              v-for="objective in userObjectives"
              :key="`${objective._id}${objective.name}`"
              class="points-objective">
              <div
                class="points-objective__wrapper"
                :class="{ 'completed': objective.scanned }">
                <!--<div class="points-objective__counter">{{ index + 1 }}.</div>-->
                <iconComponent
                  v-if="objective.scanned"
                  symbol="checkmark"
                  fill="#00693c"
                  stroke="#00693c"
                  :width="40"/>
                <iconComponent
                  v-else
                  symbol="checkmark"
                  fill="white"
                  stroke="white"
                  :width="40"/>
                <div class="points-objective__text">{{ objective.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bottom-content text-align-center"
          style="margin: 0 16px;">
          <h3>{{ game.hunt_funfacts_header }}</h3>
          <div v-html="game.hunt_funfacts[randomIndex]"></div>
        </div>
      </div>

      <div
        v-else-if="step == 'survey'"
        class="survey-wrapper">

      <!-- Trigger snackbar when finished ? and send email to participation -->
        <img v-if="game.tiebreak_image" :src="game.tiebreak_image.url" alt="">
        <div class="survey-container">
          <div class="points-grid" v-if="participation && participation.points">
            <span class="scan-text">{{ game.hunt_header }}</span>
            <span class="points-text" :style="`color: ${game.accent_color};`"> {{ participation.points.length }}/{{ game.hunt_objectives.length }}</span>
          </div>

          <div class="survey-header">{{ game.survey_header }}</div>
          <Form
            class="survey-all-questions"
            :questions="game.survey"
            :accentColor="game.accent_color"
            :textColor="game.text_color"
            :submitButton="transl.Games.submit"
            @answeredAll="surveyAnswers = $event"
            @missingRequired="surveyAnswers = {}"
            @submit="setSurvey()"/>
        </div>
      </div>
    </div>

    <modal
      v-if="mapModal"
      @close="mapModal = false"
      :header="{ closeButton: true }"
      size="xlarge">
      <slot>
        <div class="game-map"
          @click="enlargedMap = !enlargedMap">
          <img :src="game.map_image.url" alt="" class="game-map-image"
          :class="{
            'game-map-image-enlarged': enlargedMap,
          }">
        </div>
      </slot>
    </modal>

    <modal v-if="tocModal" @close="tocModal = false" size="medium" padding="0" :terms="true">
      <slot>
        <div class="terms-wrapper">
          <div v-html="game.terms_text" class="html-text"></div>
        </div>
        <div class="terms-buttons">
          <div
            v-if="authenticated && game.terms_merchandise && !participation.accepted_merchandise && !hasAcceptedGame"
            class="terms-merchandise-grid">
            <inputField
              v-model="acceptMerchandise"
              inputType="checkbox"/>
            <span
              class="terms-merchandise-text"
              v-html="game.terms_merchandise"></span>
          </div>

          <div v-if="authenticated && !hasAcceptedGame" class="btn btn-activate" @click="acceptGameTerms()">
            {{ transl.UserPV.accept }}
          </div>

          <div class="btn btn-secondary" @click="tocModal = false">
            {{ transl.UserPV.close }}
          </div>
        </div>
      </slot>
    </modal>

    <modal
      v-if="scanModal"
      @close="scanModal = false"
      size="medium"
      :header="{ closeButton: true }"> <!-- text: 'Scan the code' TODO: transl -->
      <slot>
        <QrcodeStream
          :camera="camera" @init="onCameraChange"
          style="max-width: 700px; margin: auto;"
          @decode="onDecode">
        </QrcodeStream>
      </slot>
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { QrcodeStream } from 'vue-qrcode-reader';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import BeforeEnterMixin from '../../mixins/BeforeEnterMixin';
import EntityMixin from '../../mixins/EntityMixin';
import InputField from '../../stories/components/InputField/InputField';
// import Logo from "../Logo.vue";
import Form from '../../stories/components/Form/Form';
// import { usePermission } from '@vueuse/core';
// chrome://flags/#unsafely-treat-insecure-origin-as-secure set IP-address

export default {
  name: 'QrHuntHome',
  components: {
    // Logo,
    QrcodeStream,
    InputField,
    Form
  },
  // setup() {
    //   return {
      //     usePermission,
      //   }
      // },
  props: ['gameId'],
  mixins: [BeforeEnterMixin, EntityMixin],
  data() {
    return {
      gameType: 'QrHunt',
      loadedPage: false,
      isDevelopment: process.env.NODE_ENV !== 'production',
      step: 'points',
      acceptMerchandise: true,
      acceptTerms: false,
      tocModal: false,
      scanModal: false,
      mapModal: false,
      tiebreakAnswer: '',
      surveyAnswers: [],
      firstTime: true,
      disableTryAgain: false,
      activateErrorMsg: null,
      currentUnix: (new moment).unix(),
      timeInterval: null,
      randomIndex: 0,
      camera: 'auto',
      enlargedMap: false,
    };
  },
  mounted() {
    this.getGame();
    /*this.timeInterval = setInterval(() => {
      this.currentUnix = (new moment).unix();
      // TODO: if game starts during time interval (reload page? or set canAddParticipation = true)
    }, 1000);*/
  },
  destroyed() {
    // clearTimeout(this.timeInterval);
  },
  watch: {
    authenticated(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getGame();
      }
    },
  },
  computed: {
    ...mapGetters([
      'authenticated',
      'user',
      'participation',
      'canAddParticipation',
      'reason',
    ]),
    cssProps() {
      return {
        '--game-desktop-bg-color': this.game.desktop_bg_color,
        '--game-bg-color': this.game.bg_color,
        '--game-accent-color': this.game.accent_color,
        '--game-text-color': this.game.text_color,
      }
    },
    userObjectives() {
      let userObjectives = [];
      if (this.participation && this.game) {
        for (let i = 0; i < this.game.hunt_objectives.length; i++) {
          let objective = _cloneDeep(this.game.hunt_objectives[i]);
          if (this.participation.points.includes(objective.qr_id)) {
            objective.scanned = true;
          }
          userObjectives.push(objective);
        }
      }
      return userObjectives;
    },
    hasCurrentParticipation() {
      return this.participation && this.participation._id;
    },
    hasAcceptedGame() {
      if (!this.user || !this.user._id) {
        return false;
      }
      return this.game.accepted_terms;
    },
  },
  methods: {
    getGame() {
      this.$store.dispatch('getGame', this.attach({ authenticated: this.authenticated }))
      .then((success) => {
        if (!success) {
          if (!this.game || !this.game._id) {
            this.setAlert(this.reason);
            this.$router.push({ name: 'UserStart' });
            return;
          }
          this.gotoError();
          return;
        }

        // this.usePermission('camera');
        this.setPageTitle(this.game.name, this.game.company.name);
        this.acceptMerchandise = this.game.terms_merchandise_default;
        this.setRandomFact();
        this.pushDataLayer({
          parentCompany: this.game.company.parentCompany,
          company: this.game.company,
          game: this.game,
          action: 'Enter',
        });

        this.setStep();
      });
    },
    setStep() {
      const game = this.game;
      const participation = this.participation;
      if ((!this.authenticated && game.must_login)
        || (!this.hasCurrentParticipation && !game.tiebreak)) {
        this.gotoOnboarding();
      } else if (!this.hasCurrentParticipation && !this.canAddParticipation) {
        this.gotoError();
      } else if (!this.hasCurrentParticipation && game.tiebreak) {
        this.step = 'tiebreaker';
        if (game.terms_must_accept && !this.hasAcceptedGame) {
          this.tocModal = true;
        }
      } else if (game.hunt_objectives.length <= participation.points.length &&
        (!participation.survey_answers || !participation.survey_answers.length)) {
        this.step = 'survey';
        this.removePointQuery();
      } else if (game.hunt_objectives.length <= participation.points.length &&
        participation.survey_answers && participation.survey_answers.length) {
        this.step = 'finished';
        this.removePointQuery();
      } else if (game.hunt_objectives.length > participation.points.length) {
        this.step = 'points';
        const qrId = this.getPoint();
        if (qrId) {
          this.evaluateQrId(qrId);
        }
      }
      this.loadedPage = true;
    },
    setRandomFact() {
      if (this.game.hunt_funfacts && this.game.hunt_funfacts.length) {
        this.randomIndex = this.getRandomInt(this.game.hunt_funfacts.length);
      }
    },
    getPoint() {
      const { qrId } = this.$route.query;
      return qrId;
    },
    addPoint(qrId) {
      this.$store.dispatch('addGamePoint', this.attach({ pointId: qrId })).then((success) => {
        this.removePointQuery();
        this.setRandomFact();
        if (!success) {
          this.setAlert(this.reason);
          return;
        }

        this.setAlert(this.transl.Games.goodjob);
        this.setStep();
      });
    },
    startFrontCamera () {
      this.camera = 'front'
    },
    onCameraChange (promise) {
      promise.catch(error => {
        const cameraMissingError = error.name === 'OverconstrainedError';
        const triedFrontCamera = this.camera === 'front';

        if (triedFrontCamera && cameraMissingError) {
          // no front camera on this device
        }
      })
    },
    removePointQuery() {
      const query = Object.assign({}, this.$route.query);
      if (query.qrId) {
        delete query.qrId;
        this.$router.replace({ query });
      }
    },
    acceptGameTerms() {
      this.tocModal = false;
      this.acceptTerms = true;
    },
    participate() {
      this.$store.dispatch('participate', this.attach({
        tiebreakAnswer: this.tiebreakAnswer,
        acceptTerms: this.acceptTerms || this.hasAcceptedGame,
        acceptMerchandise: this.acceptMerchandise,
        thirdPartyCrmName: this.thirdPartyCrmName,
      }))
      .then(() => {
        this.setStep();
      });
    },
    setSurvey() {
      this.$store.dispatch('answerGameSurvey', this.attach({ answers: this.surveyAnswers }))
      .then((success) => {
        if (!success) {
          this.setAlert(this.reason);
          return;
        }

        this.setStep();
      });
    },
    attach(obj) {
      obj.entityId = this.gameId;
      obj.entityType = this.gameType;
      return obj;
    },
    gotoError() {
      console.log('Home gotoError');
      this.$router.push({ name: 'QrHuntError' });
    },
    gotoOnboarding() {
      console.log('Home gotoOnboarding');
      const query = this.$route.query;
      this.$router.push({ name: 'QrHuntOnboarding', query });
    },
    onDecode(result) {
      const parsedUrl = new URL(result);
      const qrId = parsedUrl ? parsedUrl.searchParams.get("qrId") : undefined;

      this.scanModal = false;
      if (result.includes('bitly.com') || result.includes('qrco.de')) {
        return location.assign(result);
      } else if (result.includes('/onboarding')) {
        return this.setAlert('Good, now hit the slopes!'); // TODO: transl
      } else if (!result.includes('/qr-hunt/')) {
        return this.setAlert(`Not our url: ${result}`); // TODO: transl
      }

      this.evaluateQrId(qrId);
    },
    evaluateQrId(qrId) {
      if (!qrId || qrId === 'undefined') {
        this.setAlert(this.transl.Games.incorrectqrcode);
      } else if (qrId == 'onboarding') {
        this.gotoOnboarding();
      } else {
        this.addPoint(qrId);
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    ctaDoneRedirect() {
      if (this.game.cta_done_url) {
        this.openUrl(this.game.cta_done_url, true);
      } else {
        this.$router.push({ name: 'HomeCompany', params: { companyId: this.getId(this.game.company) } });
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        let message = `ERROR: Camera error (${error.name})`;
        if (error.name === 'NotAllowedError') {
          message = "ERROR: you need to grant camera access permission";
        } else if (error.name === 'NotFoundError') {
          message = "ERROR: no camera on this device";
        } else if (error.name === 'NotSupportedError') {
          message = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === 'NotReadableError') {
          message = "ERROR: is the camera already in use?";
        } else if (error.name === 'OverconstrainedError') {
          message = "ERROR: installed cameras are not suitable";
        } else if (error.name === 'StreamApiNotSupportedError') {
          message = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === 'InsecureContextError') {
          message = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        }
        // eslint-disable-next-line
        console.error(message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/games/QrHunt/Base";
@import "../../assets/scss/games/QrHunt/Home";
</style>
