<template>
  <div class="--myproducts">
    <div>
      <div>
        <div class="--myproducts-title-row">
          <h2>
            {{ this.transl.UserProfile.myhistory }}
          </h2>
        </div>
        <div class="card-container">
          <a :href="usedOffer.external_link" v-for="usedOffer in uniqueUsedOffers" :key="usedOffer._id">
            <div class="card">
              <div
                v-if="usedOffer.thumbnail && usedOffer.thumbnail.url"
                class="card-image"
                :style="{ backgroundImage: 'url(' + usedOffer.thumbnail.url + ')' }">
              </div>

              <div
                v-else-if="usedOffer.media && usedOffer.media.length && usedOffer.media[0].fileType.includes('image')"
                class="card-image"
                :style="{ backgroundImage: 'url(' + usedOffer.media[0].url + ')' }">
              </div>

              <video
                v-else-if="usedOffer.media && usedOffer.media.length && usedOffer.media[0].fileType.includes('video')"
                class="card-image"
                :controls="false"
                muted
                playsinline
                autoplay>
                <source
                  :src="`${usedOffer.media[0].url}`"
                  :type="usedOffer.media[0].fileType"/>
                {{ transl.Goodiebag.novideo }}
              </video>

              <div class="card-header-wrapper">
                <div class="card-header">

                  <p class="title">{{ usedOffer.first_header }}</p>

                </div>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="column">
                    <h5>Last visit</h5>
                    <p> {{ formattedDate(usedOffer.updated_at) }}</p>
                  </div>

                </div>

              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <UserClubProfileFooter :serverVersion="serverVersion" @openSupportModal="$emit('openSupportModal')">
    </UserClubProfileFooter>
    <modal v-if="receiptModalVisible" @close="receiptModalVisible = false" :header="{ closeButton: true }"
      modalType="fullscreen" size="xlarge">
      <slot>
        <div class="game-map" @click="receiptModalVisible = !receiptModalVisible">
          <img :src="selectedImage" alt="" class="game-map-image">
        </div>

      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserClubProfileFooter from './UserClubProfileFooter.vue';

export default {
  name: 'UserClubProfileMyHistory',
  components: { UserClubProfileFooter },
  props: {
    serverVersion: {
      type: String,
      required: false,
      default: ''
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters(['user', 'company', 'userStatus']),
    uniqueUsedOffers() {
      // Use a Set to remove duplicates
      const uniqueOffersSet = new Set();
      this.user.used_offers.forEach((offer) => {
        uniqueOffersSet.add(offer._id);
      });
      /*this.user.claimed_offers.forEach((offer) => {
        uniqueOffersSet.add(offer._id);
      });
      this.unlockedOffers.forEach((offer) => {
        uniqueOffersSet.add(offer._id);
      });
      this.user.visited_offers.forEach((offer) => {
        uniqueOffersSet.add(offer._id);
      });*/

      // Convert the Set back to an array
      let uniqueHistories = Array.from(uniqueOffersSet, (offerId) => {
        return this.user.used_offers.find((offer) => {
          return offer._id === offerId;
        });
      });

      return uniqueHistories.filter(history => {
        if (history.company == this.company._id || history.company.unique_name == this.company.unique_name) {
          return history;
        }
      });
    },
  },
  data() {
    return {
      history: [
        { name: "Ambilight modes", lastvisit: "2021-05-05", media: "" },
        { name: "Looks good. Does good.", lastvisit: "2023-02-05", media: "" }
      ],
      receiptModalVisible: false,
      selectedImage: null
    };
  },
  methods: {
    formattedDate(unix) {
      const date = new Date(unix * 1000); // Multiply by 1000 to convert seconds to milliseconds
      return date.toLocaleDateString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    openModal(file) {
      this.selectedImage = file;
      this.receiptModalVisible = true;
    },
    getMyProducts(companyId) {
      this.$store.dispatch('getMyProducts', { companyId: companyId }).then((data) => {
        this.products = data.products;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/UserProfile";



.card-container {
  display: grid;
  justify-content: space-between;
  flex-direction: row;
  grid-template-columns: repeat(2, 1fr);
  gap: 23px;

  @media (max-width: $desktop-minwidth) {
    display: block;
    padding: 0px !important;
    flex-direction: column;
    align-content: center;
  }
}

.--myproducts-title-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0 40px 0;

  h2 {
    font-family: var(--regular-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0px;
  }
}

a {
  color: #fff !important;
}

.title {
  font-family: var(--regular-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  padding: 0 0 8px 0;

}

.card {
  font-family: var(--bold-font-family);
  overflow: hidden;
  background-color: #fff;
  flex: 1;
  border-radius: 10px;
  background: #FFF;
  margin-right: 23px;
  margin-bottom: 23px;

  width: 100%;
  /* Small */
  box-shadow: 0px 2px 4px 0px rgba(31, 41, 55, 0.06), 0px 4px 6px 0px rgba(31, 41, 55, 0.10);

  @media (max-width: $desktop-minwidth) {
    margin-right: 0;
  }

  .card-image {
    background-size: cover;
    background-position: center;

    width: 100%;
    height: 225px;
    max-height: auto;
  }
}

.card-header-wrapper {
  padding: 20px 10px 0 10px;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F2F2F2;
  }
}


/* Style the card body (two columns of text) */
.card-body {
  padding: 10px;

  .row {
    min-height: 43px;
  }

  h5 {
    font-family: var(--regular-font-family);
    margin: 0;
    font-weight: 100;
    text-align: left;
    color: #6C6C6C;
  }

  p {
    font-weight: 400;
    text-align: left;
  }
}

/* Style the columns */
.column {
  float: left;
  width: 50%;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    color: #000;
  }
}
</style>
