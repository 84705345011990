<template>
  <div
    v-if="loadedPage"
    class="admin-content--container">
    <div class="admin-search-container">
      <InputField
        v-model="query"
        placeholder="Find retailer..."
        trailingIcon="magnifyingGlass"
        clickableTrailingIcon
        @trailingIconClick="findAdminRetailers()"
        @enterKey="findAdminRetailers()"/>

      <div class="add-entity-button" @click="showRetailerModal = true">
        <iconComponent symbol="circledPlus" />
      </div>
      <div
        @click="importModal = true"
        class="btn btn-transparent other-buttons">
        Import
      </div>
    </div>

    <aList
      entityType="PV Retailers"
      columnsWidth="400"
      :entities="retailers"
      :keys="retailerKeys"
      routerName="AdminPVRetailer"
      routerId="retailerId"/>

    <modal v-if="showRetailerModal"
      :header="{text: 'Add pv retailer', closeButton: true, textPosition: 'center', bgColor: '#F2F2F2'}"
      @close="showRetailerModal = false"
      class="modal-view"
      modalType="fullscreen">
      <slot>
        <EntityEditor
          title="Create retailer"
          :admin="true"
          entity-type="PVRetailer"/>
      </slot>
    </modal>

    <modal v-if="importModal" :header="{closeButton: true, text: 'Import'}"
      @close="importModal = false" size="medium">
      <slot>
        <InputField
          label="Import by name"
          inputType="textarea"
          v-model="importString"/>
        <div class="btn btn-transparent" @click="importEntity()">Import</div>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EventBus from "../resources/eventBus";
import aList from "../components/AList";
import InputField from '../stories/components/InputField/InputField.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "AdminPVRetailers",
  components: {
    aList,
    EntityEditor,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.findAdminRetailers();
    this.setPageTitle('Admin', 'PV Retailers');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('pvRetailers', ["retailers", "retailersStatus"])
  },
  data() {
    return {
      showRetailerModal: false,
      loadedPage: false,
      query: '',
      retailerKeys: [
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Chain',
          key: 'chain.name'
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
      importModal: false,
      importString: '',
      importJsonMessage: {},
    };
  },
  methods: {
    findAdminRetailers() {
      this.$store.dispatch('pvRetailers/findAdminPVRetailer', { query: this.query }).then(() => {
        this.loadedPage = true;
      });
    },
    async importEntity() {
      const response = await this.$store.dispatch(`pvRetailers/importAdminRetailers`, {
        importString: this.importString,
      });
      const { message, jsonMessage } = response;
      this.importJsonMessage = jsonMessage;
      this.setAlert(message, 20);
      setTimeout(() => {
        this.setVariables();
      }, 2);
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-overlay {
  position: absolute;
  top: 25%;
  width: 97%;
  margin: auto;
  z-index: 1000;
}
.adver-card {
  border: 1px solid grey;
}
.retailer-list {
}
.single-list{
}
.the-list {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1px;
  .a-list-item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid grey;
    height: 100px;
    .retailer-item-image{
      width: fit-content;
      height: fit-content;
    }
    .retailer-item-title{
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
    }
  }
}
.retailer-preview{
  display: grid;
  // box-shadow: -1px 0px 2px grey;
  min-height: calc(100vh - 48px);
  .close-preview{
    cursor: pointer;
    width: fit-content;
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
.add-retailer-button{
  z-index: 100;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
}
.close-preview-button{
  position: absolute;
  top: 5rem;
  right: 1rem;
}
</style>
