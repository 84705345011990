import { render, staticRenderFns } from "./LinksGallery.vue?vue&type=template&id=80c3374e&scoped=true"
import script from "./LinksGallery.vue?vue&type=script&lang=js"
export * from "./LinksGallery.vue?vue&type=script&lang=js"
import style0 from "./LinksGallery.vue?vue&type=style&index=0&id=80c3374e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80c3374e",
  null
  
)

export default component.exports