<template>
  <div
       v-if="loadedPage"
       class="admin-content--container">
    <div class="admin-search-container">
      <InputField
        v-model="query"
        placeholder="Find collection..."
        trailingIcon="magnifyingGlass"
        clickableTrailingIcon
        @trailingIconClick="findAdminGoodiebags()"
        @enterKey="findAdminGoodiebags()"/>
      
      <div class="add-entity-button" @click="showCollectionDialog = true">
        <iconComponent symbol="circledPlus" />
      </div>

      <div
        class="column-picker"
        @focus="filterOptions = true"
        @blur="filterOptions = false">
        <button
          class="btn btn-activate"
          @click="filterOptions = !filterOptions">
          Filters
        </button>
        <div
          v-show="filterOptions"
          class="column-options">
          <div
            class="column-option"
            v-for="filter in filters"
            :class="{ 'active-column-option': filter.active }"
            :key="`column-option-${filter.id}`">
            <div @click="filter.func ? filter.func() : filter.active = !filter.active">
              {{ filter.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <aList
      entityType="Collections"
      columnsWidth="400"
      :entities="goodiebags"
      :keys="goodiebagKeys"
      routerName="AdminGoodiebag"
      routerId="goodiebagId"/>


    <modal v-if="showCollectionDialog" size="xlarge"
          @close="showCollectionDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
          entity-type="Goodiebag"
          :admin="true"/>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import aList from "../components/AList";
import InputField from '../stories/components/InputField/InputField.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: "AdminGoodiebags",
  components: {
    aList,
    EntityEditor,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.setActiveFilters();

    this.findAdminGoodiebags();
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters(["goodiebag", "goodiebags", "userStatus"])
  },
  watch: {
    filters: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.filt = [];
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filter.active && !filter.func) {
            query.filt.push(filter.id);
          }
        }
        this.$router.replace({ query });
        // this.resetPagination();
        this.findAdminGoodiebags();
      },
      deep: true
    },
  },
  data() {
    return {
      showCollectionDialog: false,
      loadedPage: false,
      query: '',
      goodiebagKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'Name',
          key: 'name'
        },
        {
          title: 'Public',
          key: 'public',
          style: [{ color: 'red', value: false }, { color: 'green', value: true }],
        },
        {
          title: 'Unique Name',
          key: 'unique_name'
        },
        {
          title: 'Company',
          key: 'company.name'
        },
        /*{
          title: 'Live',
          key: 'public',
        },*/
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
      filterOptions: false,
      filters: [
        { name: 'Public', active: false, filter: { public: true } },
        { name: 'Hidden', active: false, filter: { public: false } },
        { name: 'Archived', active: false, filter: { archived: true } },
        { name: 'Not Archived', active: false, filter: { archived: false } },
      ],
      createdStart: 1672578061000,
      createdEnd: (new moment()).valueOf(),
    };
  },
  methods: {
    findAdminGoodiebags() {
      this.$store.dispatch('findAdminGoodiebags', {
        query: this.query,
        filters: this.getActiveFilters(),
        createdStart: this.createdStart/1000,
        createdEnd: this.createdEnd/1000 + 3600*24,
      }).then(() => {
        this.loadedPage = true;
        this.setPageTitle('Admin', 'Collections');
      });
    },
    setActiveFilters() {
      const { filt } = this.$route.query;
      const { created_at_start, created_at_end } = this.$route.query;
      this.createdStart = created_at_start ? Number(created_at_start) : 1672578061000;
      this.createdEnd = created_at_end ? Number(created_at_end) : (new moment()).valueOf();
      if (filt && filt.length) {
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filt.includes(filter.id)) {
            filter.active = true;
          } else if (filter.id == 'datemodal') {
            filter.active = true;
          } else {
            filter.active = false;
          }
        }
      }
    },
    getActiveFilters() {
      let realFilters = {};
      for (let i = 0; i < this.filters.length; i++) {
        const filter = this.filters[i];
        if (filter.active) {
          Object.assign(realFilters, filter.filter);
        }
      }
      return realFilters;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminGoodiebags";
</style>