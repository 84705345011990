import axios from 'axios';

export default {
  // Admin
  findAdminGame: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}`, payload)
      .then(response => ({
        games: response.data.games != null
          ? response.data.games : [],
      }));
  },

  getAdminGame: (payload) => {
    return axios.get(`/api/admin/games/${payload.entityType}/${payload.entityId}`)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : [],
      }));
  },

  addAdminGame: (payload) => {
    return axios.post(`/api/admin/games/${payload.entityType}`, payload)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {}
      }));
  },

  saveAdminGame: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}`, payload)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {}
      }));
  },
  publiciseAdminGame: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}/publicise`, payload)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
      }));
  },

  addAdminGameMedia: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}/media/${payload.mediaType}`, payload.data, payload.config)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
      }));
  },

  changeCTAs: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}/ctas`, payload)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
      }));
  },

  // TODO: not implemented yet
  sendProducts: (payload) => {
    return axios.post(`/api/admin/games/${payload.entityType}/${payload.entityId}/products/delivery`, payload)
      .then(response => ({
        numberSent: response.data.numberSent != null
          ? response.data.numberSent : 0,
      }));
  },

  // TODO: not implemented yet
  getTracking: (payload) => {
    return axios.get(`/api/admin/games/products/tracking`, payload)
      .then(response => ({
        numberSent: response.data.numberSent != null
          ? response.data.numberSent : '',
      }));
  },

  deleteAdminGame: (payload) => {
    return axios.delete(`/api/admin/games/${payload.entityType}/${payload.entityId}`)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
      }));
  },

  findAdminAllParticipations: (payload) => {
    return axios.put('/api/admin/allparticipations', payload)
      .then(response => {
        return {
          participations: response.data.participations != null
            ? response.data.participations : [],
          participationsTotal: response.data.participationsTotal != null
            ? response.data.participationsTotal : 0,
          }});
  },

  getAdminGameParticipation: (payload) => {
    return axios.get(`/api/admin/participations/${payload.entityId}`)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
      }));
  },

  addAdminGameParticipationComment: (payload) => {
    return axios.put(`/api/admin/participations/${payload.entityId}/comment`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
      }));
  },

  saveAdminGameParticipation: (payload) => {
    return axios.put(`/api/admin/participations/${payload.entityId}`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
      }));
  },

  deleteAdminGameParticipation: (payload) => {
    return axios.delete(`/api/admin/participations/${payload.entityId}`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  updateAdminParticipationByKey: (data) => {
    return axios.put(`/api/admin/participations/${data.entityId}/bykey`, data)
      .then(response => {
        return {
          participation: response.data.participation != null
            ? response.data.participation : {},
          }});
  },

  sendAdminGameReminder: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}/reminder`)
      .then(response => response);
  },

  sendAdminGameThanks: (payload) => {
    return axios.put(`/api/admin/games/${payload.entityType}/${payload.entityId}/thanks`)
      .then(response => response);
  },

  getAdminGameParticipations: (payload) => {
    return axios.get(`/api/admin/games/${payload.entityType}/${payload.entityId}/participations`)
      .then(response => response);
  },

  deleteAdminGameParticipations: (payload) => {
    return axios.delete(`/api/admin/games/${payload.entityType}/${payload.entityId}/participations`)
      .then(response => response);
  },

  // Company
  findCompanyGame: (payload) => {
    return axios.put(`/api/c/games/${payload.entityType}`, payload)
      .then(response => ({
        games: response.data.games != null
          ? response.data.games : [],
      }));
  },

  findCompanyAllParticipations: (payload) => {
    return axios.put('/api/c/allparticipations', payload)
      .then(response => {
        return {
          participations: response.data.participations != null
            ? response.data.participations : [],
          participationsTotal: response.data.participationsTotal != null
            ? response.data.participationsTotal : 0,
          }});
  },

  getCompanyParticipation: (payload) => {
    return axios.get(`/api/c/participations/${payload.entityId}`)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
      }));
  },

  addCompanyGameParticipationComment: (payload) => {
    return axios.put(`/api/c/participations/${payload.entityId}/comment`, payload)
      .then(response => ({
        receipt: response.data.receipt != null
          ? response.data.receipt : {},
      }));
  },

  saveCompanyGameParticipation: (payload) => {
    return axios.put(`/api/c/participations/${payload.entityId}`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
      }));
  },

  // Consumer
  getNarGame: (payload) => {
    return axios.get(`/api/nar/games/${payload.entityType}/${payload.entityId}`)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
        participation: response.data.participation != null
          ? response.data.participation : {},
        canAddParticipation: response.data.canAddParticipation != null
          ? response.data.canAddParticipation : false,
        reason: response.data.reason != null
          ? response.data.reason : '',
      }));
  },

  getGame: (payload) => {
    return axios.get(`/api/games/${payload.entityType}/${payload.entityId}`)
      .then(response => ({
        game: response.data.game != null
          ? response.data.game : {},
        participation: response.data.participation != null
          ? response.data.participation : {},
        canAddParticipation: response.data.canAddParticipation != null
          ? response.data.canAddParticipation : false,
        reason: response.data.reason != null
          ? response.data.reason : '',
        prizes: response.data.prizes != null
          ? response.data.prizes : {},
      }));
  },

  participate: (payload) => {
    return axios.post(`/api/games/${payload.entityType}/${payload.entityId}/participate`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  addGamePoint: (payload) => {
    return axios.post(`/api/games/${payload.entityType}/${payload.entityId}/point`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
      }));
  },

  claimPrize: (payload) => {
    return axios.post(`/api/games/${payload.entityType}/${payload.entityId}/claim`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
        prize: response.data.prize != null
          ? response.data.prize : {},
      }));
  },

  spinTheWheel: (payload) => {
    return axios.post(`/api/games/${payload.entityType}/${payload.entityId}/spin-wheel`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
        prize: response.data.prize != null
          ? response.data.prize : {},
        prizeIndex: response.data.prizeIndex != null
          ? response.data.prizeIndex : 0,
        finalAngle: response.data.finalAngle != null
          ? response.data.finalAngle : 0,
      }));
  },

  answerGameAddress: (payload) => {
    return axios.put(`/api/games/${payload.entityType}/${payload.entityId}/address`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
      }))
  },

  answerGameSurvey: (payload) => {
    return axios.put(`/api/games/${payload.entityType}/${payload.entityId}/survey`, payload)
      .then(response => ({
        participation: response.data.participation != null
          ? response.data.participation : {},
      }))
  }
};
