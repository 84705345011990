<template>
  <div>
  </div>
</template>

<script>
import Austria from './flags/icons8-austria-144.png'
import Belgium from './flags/icons8-belgium-144.png'
import Germany from './flags/icons8-germany-144.png'
import Denmark from './flags/icons8-denmark-144.png'
import Spain from './flags/icons8-spain-144.png'
import Finland from './flags/icons8-finland-144.png'
import France from './flags/icons8-france-144.png'
import Ireland from './flags/icons8-ireland-144.png'
import Italy from './flags/icons8-italy-144.png'
import Malta from './flags/icons8-malta-circular-144.png'
import Netherlands from './flags/icons8-netherlands-144.png'
import Norway from './flags/icons8-norway-144.png'
import Poland from './flags/icons8-poland-144.png'
import Portugal from './flags/icons8-portugal-144.png'
import Sweden from './flags/icons8-sweden-144.png'
import Switzerland from './flags/icons8-switzerland-96.png'
import Turkey from './flags/icons8-turkey-96.png'
import UnitedKingdom from './flags/icons8-great-britain-144.png'

export default {
  name: 'FAQDataMixin',
  data() {
    return {
      countries: [],
    }
  },
  mounted() {
    this.countries = [
      {
        id: "at",
        text: "Austria",
        route: "at",
        image: Austria
      },
      {
        id: "befr",
        text: "Belgium (French)",
        route: "be-fr",
        image: Belgium
      },
      {
        id: "benl",
        text: "Belgium (Dutch)",
        route: "be-nl",
        image: Belgium
      },
      {
        id: "de",
        text: "Germany",
        route: "de",
        image: Germany
      },
      {
        id: "dk",
        text: "Denmark",
        route: "dk",
        image: Denmark
      },
      {
        id: "es",
        text: "Spain",
        route: "es",
        image: Spain
      },
      {
        id: "fi",
        text: "Finland",
        route: "fi",
        image: Finland
      },
      {
        id: "fr",
        text: "France",
        route: "fr",
        image: France
      },
      {
        id: "ie",
        text: "Ireland",
        route: "ie",
        image: Ireland
      },
      {
        id: "it",
        text: "Italy",
        route: "it",
        image: Italy
      },
      {
        id: "mt",
        text: "Malta",
        route: "mt",
        image: Malta
      },
      {
        id: "nl",
        text: "Netherlands",
        route: "nl",
        image: Netherlands
      },
      {
        id: "no",
        text: "Norway",
        route: "no",
        image: Norway
      },
      {
        id: "pl",
        text: "Poland",
        route: "pl",
        image: Poland
      },
      {
        id: "pt",
        text: "Portugal",
        route: "pt",
        image: Portugal
      },
      {
        id: "se",
        text: "Sweden",
        route: "se",
        image: Sweden
      },
      {
        id: "chde",
        text: "Switzerland (German)",
        route: "ch-de",
        image: Switzerland
      },
      {
        id: "chfr",
        text: "Switzerland (French)",
        route: "ch-fr",
        image: Switzerland
      },
      {
        id: "tr",
        text: "Turkey",
        route: "tr",
        image: Turkey
      },
      {
        id: "uk",
        text: "United Kingdom",
        route: "uk",
        image: UnitedKingdom
      },
    ]
  },
}
</script>