/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue';
import gameApi from '../../api/game.api';

// initial state
const state = {
  game: {},
  games: [],
  gameStatus: '',

  participation: {},
  participations: [],
  participationsTotal: 0,
};

// getters
const getters = {
  game: state => state.game,
  games: state => state.games,
  gameStatus: state => state.gameStatus,

  participation: state => state.participation,
  participations: state => state.participations,
  participationsTotal: state => state.participationsTotal,
};

// actions
const actions = {
  // payload = { type: 'QrHunt' }
  // Admin
  findAdminGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.findAdminGame(payload)
      .then((response) => {
        commit('GAMES', response.games);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  getAdminGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.getAdminGame(payload)
      .then((response) => {
        commit('GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  addAdminGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.addAdminGame(payload)
      .then((response) => {
        commit('ADD_GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  addAdminGameMedia({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.addAdminGameMedia(payload)
      .then((response) => {
        commit('GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  saveAdminGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.saveAdminGame(payload)
      .then((response) => {
        commit('GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  publiciseAdminGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.publiciseAdminGame(payload)
      .then((response) => {
        commit('GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  changeCTAs({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.changeCTAs(payload)
      .then((response) => {
        commit('GAME', response.game);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  sendProducts({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.sendProducts(payload)
      .then((response) => {
        commit('GAME_STATUS', 'FINISHED');
        return response.numberSent;
      });
  },

  getTracking({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.getTracking(payload)
      .then((response) => {
        commit('GAME_STATUS', 'FINISHED');
        return response.numberSent;
      });
  },

  deleteAdminGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.deleteAdminGame(payload)
      .then(() => {
        commit('GAME_STATUS', 'FINISHED');
        return;
      });
  },

  findAdminAllParticipations({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.findAdminAllParticipations(payload)
      .then((response) => {
        const { participations, participationsTotal } = response;
        commit('PARTICIPATIONS', participations);
        commit('PARTICIPATIONS_TOTAL', participationsTotal);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  getAdminGameParticipation({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.getAdminGameParticipation(payload)
      .then((response) => {
        commit('PARTICIPATION', response.participation);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  addAdminGameParticipationComment({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.addAdminGameParticipationComment(payload)
      .then((response) => {
        commit('PARTICIPATION', response.participation);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  saveAdminGameParticipation({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.saveAdminGameParticipation(payload)
      .then((response) => {
        commit('PARTICIPATION', response.participation);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  updateAdminParticipationByKey({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.updateAdminParticipationByKey(payload)
      .then((response) => {
        commit('UPDATE_PARTICIPATION', response.participation);
        commit('GAME_STATUS', 'FINISHED');
        return;
      });
  },

  deleteAdminGameParticipation({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.deleteAdminGameParticipation(payload)
      .then((response) => {
        commit('GAME_STATUS', 'FINISHED');
        return response.message;
      });
  },

  // Company
  findCompanyGame({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.findCompanyGame(payload)
      .then((response) => {
        commit('GAMES', response.games);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  findCompanyAllParticipations({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.findCompanyAllParticipations(payload)
      .then((response) => {
        const { participations, participationsTotal } = response;
        commit('PARTICIPATIONS', participations);
        commit('PARTICIPATIONS_TOTAL', participationsTotal);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  getCompanyParticipation({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.getCompanyParticipation(payload)
      .then((response) => {
        commit('PARTICIPATION', response.participation);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  addCompanyGameParticipationComment({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.addCompanyGameParticipationComment(payload)
      .then((response) => {
        commit('PARTICIPATION', response.participation);
        commit('GAME_STATUS', 'FINISHED');
      });
  },

  saveCompanyGameParticipation({ commit }, payload) {
    commit('GAME_STATUS', 'LOADING');
    return gameApi.saveCompanyGameParticipation(payload)
      .then((response) => {
        commit('PARTICIPATION', response.participation);
        commit('GAME_STATUS', 'FINISHED');
      });
  },
};

// mutations
const mutations = {
  GAME(state, game) {
    state.game = game;
  },
  GAMES(state, games) {
    state.games = games;
  },
  ADD_GAME(state, game) {
    state.games.unshift(game);
  },
  SAVE_GAME(state, game) {
    for (let i = 0; i < state.games.length; i += 1) {
      if (state.games[i]._id === game._id) {
        Vue.set(state.games, i, game);
      }
    }
  },
  PARTICIPATION(state, participation) {
    state.participation = participation;
  },
  PARTICIPATIONS(state, participations) {
    state.participations = participations;
  },
  UPDATE_PARTICIPATION(state, participation) {
    for (let i = 0; i < state.participations.length; i++) {
      const rec = state.participations[i];
      if (rec._id == participation._id) {
        Vue.set(state.participations, i, participation);
      }
    }
  },
  PARTICIPATIONS_TOTAL(state, participationsTotal) {
    state.participationsTotal = participationsTotal;
  },
  GAME_STATUS(state, status) {
    state.gameStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
