<template>
  <div class="language-picker-wrapper">
    <div class="language-picker-background"></div>
    <div class="language-picker-logo"><img src="https://www.enginio.io/media/896f81f9-dbdc-4525-82b2-b5d1f7a82588/original.png" alt=""/></div>
    <div class="language-picker">
      <div>Welcome to Enginio - A consumer Activation platform</div>
    </div>

    <div class="language-picker-header">
      <div>Are you representing a brand that wants to drive conversion and build loyalty with your 
        customers? Click below to learn more and get in touch with us</div>
      <a class="btn btn-whitebg-border" href="https://business.enginio.io/" target="_blank">Learn More</a>
    </div>
    <LanguagePicker />
  </div>
</template>

<script>
import LanguagePickerDataMixin from '../stories/components/LanguagePicker/LanguagePickerDataMixin.vue';
import LanguagePicker from '../stories/components/LanguagePicker/LanguagePicker.vue'

export default {
  name: 'LanguagePickerView',
  components: {
    LanguagePicker,
  },
  mixins: [LanguagePickerDataMixin],
  data() {
    return {
      loadedPage: false,
      bounce: false,
      countries: [],
      pickedCountry: {},
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/LanguagePickerView"
</style>
