<template>
  <div v-if="loadedPage">
    <CollectionView 
      :collection="theCompany"
      :authenticated="true"
      :showHeader="false">

      <template #content>
        <div
          class="collection-left-button"
          @click="$router.go(-1)">
          <div class="collection-back-button">
            <iconComponent symbol="arrowLeft" fill="black" stroke="black" :strokeWidth="2"/>
          </div>
        </div>
        <div v-if="$route.name == 'UserClubProfile'">
          <UserClubProfileStart
            :serverVersion="serverVersion"
            @openSupportModal="$emit('openSupportModal', {})"></UserClubProfileStart>
        </div>
        <div v-if="$route.name == 'UserClubMyProducts'">
          <UserClubProfileMyProducts
            :serverVersion="serverVersion"
            @openSupportModal="$emit('openSupportModal', {})"></UserClubProfileMyProducts>
        </div>
        <div v-if="$route.name == 'UserClubMyFavorites'">
          <UserClubProfileMyFavorites
            :serverVersion="serverVersion"
            @openSupportModal="$emit('openSupportModal', {})"></UserClubProfileMyFavorites>
        </div>
        <div v-if="$route.name == 'UserClubMyHistory'">
          <UserClubProfileMyHistory
            :serverVersion="serverVersion"
            @openSupportModal="$emit('openSupportModal', {})"></UserClubProfileMyHistory>
        </div>
        <div v-if="$route.name == 'UserClubAbout'">
          <UserClubProfileAbout
            :serverVersion="serverVersion"
            @openSupportModal="$emit('openSupportModal', {})"></UserClubProfileAbout>
        </div>
      </template>
    </CollectionView>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ExpiredMixin from '../mixins/ExpiredMixin.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import AnnoyingClientMixin from '../mixins/AnnoyingClientMixin.vue';
import CollectionView from '../stories/components/CollectionView/CollectionView.vue';
import UserClubProfileStart from '../views/UserClubProfileStart.vue';
import UserClubProfileMyProducts from '../views/UserClubProfileMyProducts.vue';
import UserClubProfileMyFavorites from '../views/UserClubProfileMyFavorites.vue';
import UserClubProfileMyHistory from './UserClubProfileMyHistory.vue';
import UserClubProfileAbout from '../views/UserClubProfileAbout.vue';

export default {
  name: 'UserClubProfile',
  components: {
    CollectionView,
    UserClubProfileStart,
    UserClubProfileMyProducts,
    UserClubProfileMyFavorites,
    UserClubProfileMyHistory,
    UserClubProfileAbout,
  },
  props: {
    companyId: {
      type: String,
    },
    serverVersion: {
      type: String,
      required: false,
      default: ''
    }
  },
  mixins: [ExpiredMixin, BeforeEnterMixin, AnnoyingClientMixin],
  mounted() {
    this.$store
      .dispatch('getCompany', { companyId: this.companyId })
      .then(() => {
        this.loadedPage = true;
        this.setLoadedPageData(true);
        this.setPageTitle(this.company.name);
        this.pushDataLayer({
          parentCompany: this.company.parentCompany,
          company: this.company,
          goodiebag: 'None',
          offer: 'None',
          action: 'Enter',
        });
      })
      .catch((error) => {
        this.findEntityIssue(error);
      });

    this.setPageTitle(this.transl.UserSettings.accountsettings);
    this.$store.dispatch('getUser');
  },
  computed: {
    ...mapGetters([
      'user',
      'company',
    ]),
    theCompany () {
      return {
        ...this.company,
        company: this.company,
      };
    }
  },
  watch: {},
  destroyed() {
    clearTimeout(this.timeInterval);
    this.$store.dispatch('getUser');
  },
  data() {
    return {
      loadedPage: false,
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    ...mapMutations({
      setFavorite: 'SET_FAVORITE',
    }),
    gotoOffer(offerId) {
      this.$router.push({ name: 'UserOffer', params: { offerId } });
    },
    gotoParent() {
      const routeName = this.$route.name;
      if (routeName == 'UserClubProfile') {
        this.$router.push({ name: 'UserCompany', params: { companyId: this.getId(this.company) } });
      } else {
        this.$router.push({ name: 'UserClubProfile' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/UserProfile";

.collection-back-button, .collection-club-button {
  top: 24px;
  @media (max-width: $mobile-maxwidth) {
    top: 37px;
  }
}
.collection-back-button {
  left: 0;
  @media (max-width: $mobile-maxwidth) {
    left: 16px;
  }
}
.collection-club-button {
  right: 0;
  @media (max-width: $mobile-maxwidth) {
    right: 16px;
  }
}

.flexbox-list {
  display: flex;
  flex-direction: column;
}

.list-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}


.enginio-information {
  color: #666;

  /* small/regular */
  font-family: var(--regular-font-family);
  font-size: 12px;

  line-height: normal;


  .list-item {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }
}

.icon {
  width: 30px;
  margin-right: 10px;
  /* Adjust the spacing between icon and text */
}

/* Style the icon element, e.g., using a font icon library like Font Awesome */
.icon i {
  font-size: 24px;
  /* Adjust the icon size as needed */
}

.text {
  font-size: 14px;
  /* Adjust the text size as needed */
}


.title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    font-family: var(--regular-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.card {
  font-family: var(--bold-font-family);
  background-color: #fff;
  padding: 24px;
  flex: 1;
  border-radius: 10px;
  background: #FFF;
  /* Small */
  box-shadow: 0px 2px 4px 0px rgba(31, 41, 55, 0.06), 0px 4px 6px 0px rgba(31, 41, 55, 0.10);
}

/* Style the card header */
.card-header {
  display: flex;
  align-items: center;
  padding: 10px;
}
</style>