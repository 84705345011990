import { render, staticRenderFns } from "./HomeDataParticipants.vue?vue&type=template&id=598f70ba"
import script from "./HomeDataParticipants.vue?vue&type=script&lang=js"
export * from "./HomeDataParticipants.vue?vue&type=script&lang=js"
import style0 from "./HomeDataParticipants.vue?vue&type=style&index=0&id=598f70ba&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports