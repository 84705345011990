import { render, staticRenderFns } from "./HomeDataCompanies.vue?vue&type=template&id=806d958e"
import script from "./HomeDataCompanies.vue?vue&type=script&lang=js"
export * from "./HomeDataCompanies.vue?vue&type=script&lang=js"
import style0 from "./HomeDataCompanies.vue?vue&type=style&index=0&id=806d958e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports