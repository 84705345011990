<template>
  <div v-if="loadedPage" class="admin-content--container">
    <div class="admin-search-container">
      <div class="admin-spacer"/>
      
      <InputField
        v-model="query"
        placeholder="Find offer..."
        trailingIcon="magnifyingGlass"
        clickableTrailingIcon
        @trailingIconClick="findAdminOffers()"
        @enterKey="findAdminOffers()"/>

      <div class="add-entity-button" @click="showOfferDialog = true">
        <iconComponent symbol="circledPlus" />
      </div>

      <div
        class="column-picker"
        @focus="filterOptions = true"
        @blur="filterOptions = false">
        <button
          class="btn btn-activate"
          @click="filterOptions = !filterOptions">
          Filters
        </button>
        <div
          v-show="filterOptions"
          class="column-options">
          <div
            class="column-option"
            v-for="filter in filters"
            :class="{ 'active-column-option': filter.active }"
            :key="`column-option-${filter.id}`">
            <div @click="filter.func ? filter.func() : filter.active = !filter.active">
              {{ filter.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <aList
      entityType="Offers"
      columnsWidth="400"
      :entities="offers"
      :keys="offerKeys"
      routerName="AdminOffer"
      routerId="offerId"/>

    <modal v-if="showOfferDialog" size="xlarge"
          @close="showOfferDialog = false;" modalType="fullscreen">
      <slot>
        <EntityEditor
          entity-type="Offer"
          :admin="true"/>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import aList from "../components/AList";
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import InputField from '../stories/components/InputField/InputField.vue';

export default {
  name: "AdminOffers",
  components: {
    aList,
    EntityEditor,
    InputField,
  },
  mixins: [BeforeEnterMixin],
  mounted() {
    this.setActiveFilters();

    this.findAdminOffers();
    this.setPageTitle('Admin', 'Activations');
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('search', ["offers", "userStatus"])
  },
  watch: {
    filters: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.filt = [];
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filter.active && !filter.func) {
            query.filt.push(filter.id);
          }
        }
        this.$router.replace({ query });
        // this.resetPagination();
        this.findAdminOffers();
      },
      deep: true
    },
  },
  data() {
    return {
      showOfferDialog: false,
      loadedPage: false,
      query: '',
      offerKeys: [
        {
          title: 'Id',
          key: '_id'
        },
        {
          title: 'First Header',
          key: 'first_header'
        },
        {
          title: 'Unique Name',
          key: 'unique_name'
        },
        {
          title: 'Public',
          key: 'public',
          style: [{ color: 'red', value: false }, { color: 'green', value: true }],
        },
        {
          title: 'Company',
          key: 'company.name'
        },
        {
          title: 'Collection',
          key: 'goodiebag.name'
        },
        {
          title: 'Created At',
          key: 'created_at',
          unix: 'YYYY-MM-DD HH:mm:ss',
        },
      ],
      filterOptions: false,
      filters: [
        { name: 'Public', active: false, filter: { public: true } },
        { name: 'Hidden', active: false, filter: { public: false } },
        { name: 'Archived', active: false, filter: { archived: true } },
        { name: 'Not Archived', active: false, filter: { archived: false } },
      ],
      createdStart: 1672578061000,
      createdEnd: (new moment()).valueOf(),
    };
  },
  methods: {
    findAdminOffers() {
      this.$store.dispatch('search/findAdminOffers', {
        query: this.query,
        filters: this.getActiveFilters(),
        createdStart: this.createdStart/1000,
        createdEnd: this.createdEnd/1000 + 3600*24,
      }).then(() => {
        this.loadedPage = true;
      });
    },
    addOfferChild(data) {
      this.parentOfferId = data.offerId;
      this.showOfferDialog = true;
    },
    openOfferDialog(data) {
      this.offerId = data.offerId;
      this.showOfferDialog = true;
    },
    closeOfferDialog() {
      this.parentOfferId = false;
      this.showOfferDialog = false;
    },
    setActiveFilters() {
      const { filt } = this.$route.query;
      const { created_at_start, created_at_end } = this.$route.query;
      this.createdStart = created_at_start ? Number(created_at_start) : 1672578061000;
      this.createdEnd = created_at_end ? Number(created_at_end) : (new moment()).valueOf();
      if (filt && filt.length) {
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filt.includes(filter.id)) {
            filter.active = true;
          } else if (filter.id == 'datemodal') {
            filter.active = true;
          } else {
            filter.active = false;
          }
        }
      }
    },
    getActiveFilters() {
      let realFilters = {};
      for (let i = 0; i < this.filters.length; i++) {
        const filter = this.filters[i];
        if (filter.active) {
          Object.assign(realFilters, filter.filter);
        }
      }
      return realFilters;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminOffers";
</style>