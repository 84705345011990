<template>
  <div class="activation-config-container">

    <div class="tab-content-container">

      <div class="form">
        <div class="terms-container">
          <div class="checkboxes-container">
            <InputField inputType="radio" :label="'Serial number'" :value="activeRegistrationNumber === 'serial'" @input="activeRegistrationNumber = 'serial'; setRegistrationNumber('serial')" />
            <InputField inputType="radio" :label="'IMEI number'" :value="activeRegistrationNumber === 'imei'" @input="activeRegistrationNumber = 'imei'; setRegistrationNumber('imei')" />
          </div>

          <div v-if="activeRegistrationNumber === 'serial'" class="input-container">
            <p class="label">Registration serial description</p>
            <input v-model="updatedActivation.registration_serial_description"/>
          </div>

          <div v-if="activeRegistrationNumber === 'imei'" class="input-container">
            <p class="label">Registration imei description</p>
            <input v-model="updatedActivation.registration_imei_description"/>
          </div>

          <div class="input-container">
            <select class="select" v-model="selectedRegistrationInput">
              <option v-for="input in registrationInputs" :key="input" :value="input">
                {{ input }}
              </option>
            </select>
          </div>

          <div v-if="selectedRegistrationInput === registrationInputs[0]">
            <chip-area :data-field="updatedActivation.registration_terms" :label="'Registration terms'" :with-search-input="true"
                       :placeholder="'Add term...'" @change="emitChanges('registration_terms', $event)"/>
          </div>
          <div v-else>

            <div class="input-container">
              <p class="label">Registration method</p>
              <select class="select" :value="updatedActivation.registration_method" @change="updatedActivation.registration_method = $event.target.value">
                <option v-for="method in registrationMethods" :key="method" :value="method">
                  {{ method }}
                </option>
              </select>
            </div>
          </div>

        </div>

        <div class="terms-container">
          <InputField inputType="toggle" :label="'Ask also for model numbers'"
                      :value="updatedActivation.registration_modelnr"
                      @inputClick="updatedActivation.registration_modelnr = !updatedActivation.registration_modelnr"/>

          <chip-area :data-field="updatedActivation.registration_modelnrs" :label="'Registration model numbers drop down'" :with-search-input="true"
                     :placeholder="'Add model...'" @change="emitChanges('registration_modelnrs', $event)"/>
          </div>
      </div>
    </div>

  </div>
</template>

<script>

import InputField from "@/stories/components/InputField/InputField.vue";
import ChipArea from "@/stories/components/ChipArea/ChipArea.vue";


export default {
components: {
  ChipArea,

  InputField,
  },
  props: {
    activation: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'updatedActivation.registration_serial'(value) {
      this.emitChanges('registration_serial', value);
    },
    'updatedActivation.registration_serial_description'(value) {
      this.emitChanges('registration_serial_description', value);
    },
    'updatedActivation.registration_imei'(value) {
      this.emitChanges('registration_imei', value);
    },
    'updatedActivation.registration_imei_description'(value) {
      this.emitChanges('registration_imei_description', value);
    },
    'updatedActivation.registration_method'(value) {
      this.emitChanges('registration_method', value);
    },
    'updatedActivation.registration_modelnr'(value) {
      this.emitChanges('registration_modelnr', value);
    },
  },
  created() {
    this.activeRegistrationNumber = this.activation.registration_serial ? 'serial' : 'imei';
  },
  data() {
    return {
      updatedActivation: this.activation,
      registrationInputs: ['Registrations terms', 'Registration method'],
      registrationMethods: ['GOOGLE_IMEI_MAY_23', 'PHILIPS_SERIAL', 'MIN_FIVE_CHARS', 'MIN_FIVE_NUMBERS', 'EXACTLY_FOURTEEN_CHARS', 'EXACTLY_FOURTEEN_NUMBERS', 'EXACTLY_FIFTEEN_CHARS', 'EXACTLY_FIFTEEN_NUMBERS'],
      selectedRegistrationInput: 'Registrations terms',
      activeRegistrationNumber: 'imei',
    }
  },
  methods: {
    emitChanges(keyOrChange, value) {
      this.$emit('changeData', typeof value !== undefined
        ? { [keyOrChange]: value }
        : { ...keyOrChange }
      );
    },
    setRegistrationNumber(method) {
      if (method === 'serial') {
        this.updatedActivation.registration_serial = true;
        this.updatedActivation.registration_imei = false;
      } else {
        this.updatedActivation.registration_serial = false;
        this.updatedActivation.registration_imei = true;
      }
    },
  }
}

</script>

<style lang="scss" scoped>

@import "./ActivationStep";

.checkboxes-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 16px;
}

.terms-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
  padding: 16px 8px;
  border: 1px solid $gray-100;
}

</style>

