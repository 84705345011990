<template>
  <div v-if="user && user._id" class="user-history">
    <div class="-header">
      <h3>{{ transl.UserProfile.myhistory }}</h3>
      <div @click="$router.push({ name: 'UserProfile'}); $emit('back')" class="back-button--container --mobile">
        <iconComponent class="settings--back-button" symbol="arrowLeft" />
      </div>
    </div>

    <div v-if="userHistory.length > 0" class="--user-history">
      <div class="pre-title-small">{{ transl.UserProfile.pastactivations }}</div>
      <div v-for="(item, index) in userHistory" :key="index">
        <router-link
          v-if="item.model_type == 'Offer'"
          :to="{ name: 'UserOffer', params: { offerId: item._id }}"
          class="--container">
          <img v-if="item.media && item.media[0] && item.media[0].fileType.includes('image')"
               :src="item.media[0].url" alt="">
          <div v-else class="--icon-container">
            <iconComponent class="--icon" symbol="rocket" fill="#EA7801" stroke="#EA7801" />
          </div>
          <div v-if="item.quick_text" class="body-2">{{ item.quick_text  }}</div>
          <div v-else class="body-2" style="max-width: 75%">{{ item.first_header || item.unique_name }}</div>
        </router-link>
        <!---if="item.model_type == 'Receipt'"-->
        <router-link
          v-else
          class="--container"
          :to="{ name: 'UserPV', params: { pvId: item._id }}">
          <img v-if="item.fe_media?.file"
               :src="item.fe_media.file" alt="">
          <div v-else class="--icon-container">
            <iconComponent class="--icon" symbol="rocket" fill="#EA7801" stroke="#EA7801" />
          </div>
          <div class="body-2" style="max-width: 75%">{{ item.title || item.name || item.unique_name }}</div>
        </router-link>
      </div>
    </div>

    <div v-else class="--no-history">
      <div class="body-1">{{ transl.UserProfile.nohistoryyet }}</div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import IconComponent from '../stories/components/IconComponent/IconComponent.vue';

export default {
  name: 'UserHistory',
  components: {
    IconComponent,
  },
  mixins: [BeforeEnterMixin],
  watch: {},
  computed: {
    ...mapGetters([
      'user',
      'unlockedOffers',
      'unlockedGoodiebags',
      'favoriteOffers',
      'favoriteGoodiebags',
      'userStatus',
      'myActivations',
    ]),
  },
  data() {
    return {
      userHistory: [],
    };
  },
  async mounted() {
    const allOffers = [
      ...this.user.used_offers,
      // ...this.user.claimed_offers,
      // ...this.user.donated_offers,
      // ...this.user.active_offers,
      ...this.unlockedOffers,
      ...this.unlockedGoodiebags,
      ...this.favoriteOffers,
      ...this.favoriteGoodiebags,
      ...this.myActivations,
    ];

    // Use a Set to remove duplicates
    const uniqueOffers = Array.from(new Set(allOffers.map(offer => offer._id)))
      .map(id => allOffers.find(offer => offer._id === id));
    this.userHistory = uniqueOffers;

    this.setPageTitle(this.transl.UserProfile.myhistory);
  },
  methods: {
    capName(name) {
      const cappedName = name.charAt(0).toUpperCase() + name.slice(1);
      const truncatedName = cappedName.length > 30 ? cappedName.slice(0, 30) : cappedName;
      if (cappedName.length > 30) {
        return `${truncatedName}...`;
      }
      return truncatedName;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/UserHistory";
</style>
