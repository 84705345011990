<template>
  <div>
    <EconomyEditor
      :admin="true"
      :companyId="companyId"/>
    <!--<EmailTemplate v-if="user && isAdmin(user)" />
    <div v-else>Sorry, you must be an admin to proceed</div>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import EmailTemplate from '../stories/components/EmailTemplate/EmailTemplate.vue';
import EconomyEditor from '../stories/components/Economy/EconomyEditor.vue';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';

export default {
  name: 'AdminCompanyEconomy',
  components: {
    // EmailTemplate,
    EconomyEditor,
  },
  props: ['companyId'],
  mixins: [BeforeEnterMixin],
  computed: {
    ...mapGetters([
      "company",
    ]),
    ...mapGetters(["user"])
  },
};
</script>

<style scoped lang="scss">
</style>
