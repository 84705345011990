<template>
  <div class="content-creator">
    <div class="content-creator-menu-left">
      <div class="btn-devicepicker">
        <div class="-phone"  @click="deviceSelected = 'mobile'">
          <iconComponent class="clickable" symbol="smartphone" :fill="deviceSelected == 'mobile' ? 'black' : 'gray'" :stroke="deviceSelected === 'mobile' ? 'black' : 'gray'"/>
        </div>
        <div class="--tablet"  @click="deviceSelected = 'tablet-device'">
          <iconComponent class="clickable" symbol="tablet" :fill="deviceSelected == 'tablet-device' ? 'black' : 'gray'" :stroke="deviceSelected === 'tablet' ? 'black' : 'gray'"/>
        </div>
        <div class="-desktop"  @click="deviceSelected = 'desktop'">
          <iconComponent class="clickable" symbol="computer" :fill="deviceSelected == 'desktop' ? 'black' : 'gray'" :stroke="deviceSelected === 'computer' ? 'black' : 'gray'"/>
        </div>
      </div>

      <collapsable
        :header="{
          bgColor: '#F9D7B3',
          textPosition: 'left'
        }"
        :content="{ bgColor: 'white' }"
        :collapsed="addContentOpen">
        <template v-slot:header>
          <h3>Create</h3>
        </template>

        <template v-slot:content>
          <button
            class="btn btn-activate"
            @click="openTemplateModal = true">Templates</button>
          <button
            class="btn btn-activate"
            @click="addRow()">Add Row</button>
          <draggable
            class="clone-cells"  
            :list="availableCells"
            :sort="false"
            :group="{ name: 'cells', pull: 'clone', put: false }"
            :clone="handleClone">
            <div
              v-for="(cell, index) in availableCells"
              :key="`cloneCell${index}`"
              class="clone-cell"
              :style="{
                backgroundColor: cell.type === 'text' ? 'white' : '',
              }">
              <ContentText v-if="cell.type == 'text'" :text="`${cell.text.substring(0, 40)}...`"/>
              <button v-else-if="cell.type == 'button'" class="btn btn-activate">{{ cell.text }}</button>
              <ContentTable v-else-if="cell.type == 'table'" :table="cell.table"/>
              <ContentImage v-else-if="cell.type == 'image'" :src="cell.media.url" :alt="cell.alt" width="100px"/>
              <ContentVideo v-else-if="cell.type == 'video'" :src="cell.media.url" :alt="cell.alt" width="100px"/>
              <ContentIframe v-else-if="cell.type == 'iframe'" :src="cell.url" width="100px"/>
            </div>
          </draggable>
        </template>
      </collapsable>

      <collapsable
        :header="{
          bgColor: '#F9D7B3',
          textPosition: 'left'
        }"
        :content="{ bgColor: 'white' }"
        :collapsed="contentSettingsOpen">
        <template v-slot:header>
          <h3>Change Content</h3>
        </template>

        <template v-slot:content>
          <div>
            <inputField
              label="Content name"
              v-model="contentName"/>
            <ColorSelect
              :title="'Background color'"
              :color="localContent.backgroundColor"
              @select="selectColor($event)"/>
            <inputField
              label="Max width"
              inputType="select"
              v-model="localContent.maxWidth"
              :selectOptions="maxWidthOptions"/>
            <inputField
              label="Border radius"
              inputType="select"
              v-model="localContent.borderRadius"
              :selectOptions="borderRadiusOptions"/>
            <inputField
              label="Grid gap"
              inputType="select"
              v-model="localContent.gridGap"
              :selectOptions="gridGapOptions"/>
            <inputField
              label="Margin"
              v-model="localContent.margin"/>
            <!--<inputField
              label="Padding"
              v-model="localContent.padding"/>-->
          </div>
        </template>
      </collapsable>

      <collapsable
        :header="{
          bgColor: '#F9D7B3',
          textPosition: 'left'
        }"
        :content="{ bgColor: 'white' }"
        :collapsed="companyMediaOpen">
        <template v-slot:header>
          <h3>Company Assets</h3>
        </template>
        
        <template v-slot:content>
          <button
            class="btn btn-activate"
            @click="uploadModal = true">Add Asset</button>

          <div class="media-wrapper">
            <div
              v-for="media in assets"
              :key="media.id"
              draggable="true"
              @dragstart="dragStart(media)"
              @click="copyText(getImageUrl(media), 'URL')">
              <video v-if="media?.fileType?.includes('video')" :src="media.url" controls></video>
              <div v-else-if="media?.fileType?.includes('pdf')" style="word-break: break-all;">
                <!--<div v-if="windowOrigin.includes('localhost')">
                  {{ getImageUrl(media) }}
                </div>-->
                {{ media.name }}
                <!--<iconComponent symbol="folder" fill="black"/>-->
                <!--<vuePdf :src="getImageUrl(media)"></vuePdf>-->
              </div>
              <img v-else :src="media.url" alt="">
            </div>
          </div>
        </template>
      </collapsable>

      <div class="button-wrapper">
        <button
          class="btn btn-activate"
          @click="newContentModal = true">New Content</button>
        <button
          v-if="localContent._id"
          class="btn btn-activate"
          @click="saveAdminContent()">Save Content</button>
        <button
          class="btn btn-activate"
          @click="openContentModal = true">Open Content</button>
      </div>
      <div class="button-wrapper">
        <button
          class="btn btn-activate"
          @click="copyHtml()">Copy html</button>
        <button
          class="btn btn-activate"
          @click="exportHtml()">Export html</button>
      </div>
    </div>

    <div
      class="the-content"
      :class="deviceSelected">
      <Content
        :edit="contentEdit"
        :class="deviceSelected"
        :dpr="deviceSelected == 'mobile' ? 4 : 1"
        v-model="localContent"
        @editRow="editRow"
        @editCell="editCell"
        @verticalDragEnd="resetEdit"/>

      <InputField
        class="content-edit-toggle"
        inputType="toggle"
        :label="contentEdit ? 'Edit mode' : 'Preview mode'"
        :value="contentEdit"
        @inputClick="contentEdit = !contentEdit"/>
      <button
        class="content-edit-toggle btn btn-activate"
        @click="helpModal = !helpModal">?</button>
      <div class="trashcan-icon">
        <iconComponent
          symbol="trashcan"
          @click="openTrashcan = !openTrashcan"/>
      </div>
      <draggable
        v-if="openTrashcan"
        class="clone-cells trashcan-bag" 
        :list="trashCells"
        :sort="false"
        group="cells">
        <div
          v-for="(cell, index) in trashCells"
          :key="`cloneCell${index}`"
          class="clone-cell"
          :style="{
            backgroundColor: cell.type === 'text' ? 'white' : '',
          }">
          <ContentText v-if="cell.type == 'text'" :text="`${cell.text.substring(0, 40)}...`"/>
          <button v-else-if="cell.type == 'button'" class="btn btn-activate">{{ cell.text }}</button>
          <ContentTable v-else-if="cell.type == 'table'" :table="cell.table"/>
          <ContentImage v-else-if="cell.type == 'image'" :src="cell.media.url" :alt="cell.alt" width="100px"/>
          <ContentVideo v-else-if="cell.type == 'video'" :src="cell.media.url" :alt="cell.alt" width="100px"/>
          <ContentIframe v-else-if="cell.type == 'iframe'" :src="cell.url" width="100px"/>
        </div>
      </draggable>
    </div>

    <div class="content-creator-menu-right">
      <div v-if="editTheRow">
        <h3>Edit Row</h3>

        <div>
          <ColorSelect
            :title="'background color'"
            :color="editTheRow.backgroundColor"
            @select="selectColor($event, true)"/>
          <!--<inputField v-model="editTheRow.borderRadius"/>-->
          <inputField
            label="Display"
            inputType="select"
            v-model="editTheRow.display"
            :selectOptions="displayOptions"/>
          <inputField
            v-if="editTheRow.display == 'grid'"
            label="Grid template columns"
            inputType="select"
            v-model="editTheRow.gridTemplateColumns"
            :selectOptions="gridTemplateColumnsOptions"/>
          <inputField
            v-if="editTheRow.display == 'grid' || editTheRow.display == 'flex'"
            label="Grid gap"
            inputType="select"
            v-model="editTheRow.gridGap"
            :selectOptions="gridGapOptions"/>
          <inputField
            label="Padding"
            v-model="editTheRow.padding"/>
          <inputField
            label="Border radius"
            v-model="editTheRow.borderRadius"/>
        </div>

        <div class="content-creator-dívider"></div>
        <button
          v-if="editTheRow.cells.length == 0"
          class="btn btn-transparent"
          @click="removeRow()">Remove Row</button>
      </div>

      <div v-else-if="editTheCell">
        <h3>Edit Cell ({{ editTheCell.type }})</h3>

        <div v-if="editTheCell.type == 'text'">
          <CKEditor v-model="editTheCell.text"/>
        </div>
        <div v-else-if="editTheCell.type == 'table'">
          <CKEditor v-model="editTheCell.table"/>
        </div>
        <div v-else-if="editTheCell.type == 'button'">
          <inputField
            label="Text"
            v-model="editTheCell.text"/>
          <inputField
            label="URL"
            v-model="editTheCell.url"/>
        </div>
        <div
          v-else-if="editTheCell.type == 'image'"
          @drop="drop"
          @dragover.prevent>
          <ContentImage :src="editTheCell.media.url" :alt="editTheCell.alt" width="100px"/>
          {{ editTheCell.media }}
          {{ editTheCell.media.url }}
        </div>
        <div
          v-else-if="editTheCell.type == 'video'"
          @drop="drop"
          @dragover.prevent>
          <ContentVideo :src="editTheCell.media.url" :alt="editTheCell.alt" width="100px"/>
          {{ editTheCell.media.url }}
        </div>
        <div v-else-if="editTheCell.type == 'iframe'">
          <input type="text" v-model="editTheCell.iframe">
        </div>

        <div class="content-creator-dívider"></div>
        <ColorSelect
          :title="'Background color'"
          :color="editTheCell.backgroundColor"
          @select="selectColor($event, false, true)"/>
        <inputField
          label="Border radius"
          inputType="select"
          v-model="editTheCell.borderRadius"
          :selectOptions="borderRadiusOptions"/>
        <inputField
          label="Margin"
          v-model="editTheCell.margin"/>
        <inputField
          label="Padding"
          v-model="editTheCell.padding"/>
      </div>
      <b v-else>
        <br/>
        <div>Click the pencil icon in order to edit the row</div>
        <br/>
        <div>Doubleclick a cell in order to edit it</div>
      </b>
    </div>

    <modal
      v-if="openTemplateModal"
      :header="{ text: 'Templates' }"
      @close="openTemplateModal = false">
      <slot>
        <div class="template-modal">
          <h2>!! Important !!</h2>
          <div>When you click a template below, your current progress will be lost</div>
          <div class="templates">
            <div
              v-for="(template, key) in templates"
              :key="template.name"
              class="btn btn-transparent template-button"
              @click="chooseTemplate(key)">
              <iconComponent
                v-if="template.symbol"
                :symbol="template.symbol"
                :fill="brandButtonColor"
                :stroke="brandButtonColor"/>
              {{ template.name }}
            </div>
          </div>
        </div>
      </slot>
    </modal>

    <modal
      v-if="helpModal"
      :header="{ text: 'Help' }"
      @close="helpModal = false">
      <slot>
        <h3>Margin, Padding & Border radius</h3>
        <div class="help-modal-content-wrapper">
          <div
            class="help-modal-content-margin"
            :style="{
              padding: helpModalMargin,
            }">
            <div
              class="help-modal-content-padding"
              :style="{
                padding: helpModalPadding,
                border: '1px solid black',
                borderRadius: helpModalBorderRadius,
              }">
              <div class="help-modal-content">Test how margins and paddings work</div>
            </div>
          </div>
        </div>
        <div class="help-modal-examples">
          <b class="help-modal-content-margin">Margin</b>
          <br/>
          <b class="help-modal-content-padding">Padding</b>
          <br/>
          <b>Border, which has a radius</b>
          <br><br/>
          <div>Margin/padding examples:</div>
          <div><b>10px</b> => 10px on every side</div>
          <div><b>10px 20px</b> => 10px top and bottom, 20px left and right</div>
          <div><b>10px 20px 0 10px</b> => 10px top, 20px right, 0px down, 10px left</div>
          
          <br><br/>
          <div>Border Radius examples:</div>
          <div><b>10px</b> => 10px on every corner</div>
          <div><b>5px 10px</b> => 5px top corners, 10px bottom corners</div>
          <div><b>5px 10px 0 5px</b> => 5px top-left, 10px top-right, 0px bottom-right, 5px bottom-left</div>
        </div>
        <inputField
          label="Margin"
          v-model="helpModalMargin"/>
        <inputField
          label="Padding"
          v-model="helpModalPadding"/>
        <inputField
          label="Border radius"
          v-model="helpModalBorderRadius"/>

          <div class="content-creator-dívider"></div>

          <div style="text-align: left;">
            <h3>Variables that can be used in the email template:</h3>
            <b>Usable values when sending emails from an Activation:</b>
            <p v-html="'{{receiptName}} => Elliott'"></p>
            <p v-html="'{{receiptLastName}} => Jönsson'"></p>
            <p v-html="'{{receiptPhoneNr}} => +4611313333'"></p>
            <p v-html="'{{receiptEmail}} => elliot@jonsson.se'"></p>
            <p v-html="'{{receiptDate}} => 2024-03-01'"></p>
            <p v-html="'{{receiptCode}} => DIN-UNIKA-KOD'"></p>
            <p v-html="'{{enginioUrl}} => https://www.enginio.io/xx'"></p>
            <p v-html="'{{activationId}} => asd123asd123'"></p>
            <p v-html="'{{activationUrl}} => https://www.enginio.io/xx/pv/asd123asd123/offer-one-24'"></p>
            <p v-html="'{{activationName}} => Buy and get 2024'"></p>
            <p v-html="'{{activationMedia}} => PV Activation background image'"></p>
            <p v-html="'{{offerId}} => qwe321qwe321'"></p>
            <p v-html="'{{offerUniqueName}} => offer-one-24'"></p>
            <p v-html="'{{offerUrl}} => https://www.enginio.io/xx/a/offer-one-24'"></p>
            <p v-html="'{{offerName}} => Buy and get, landing'"></p>
            <p v-html="'{{offerMedia}} => Enginio Offer media (first image if a carousel)'"></p>
            <p v-html="'{{offerCollectionName}} => Enginio collection name'"></p>
            <p v-html="'{{offerCompanyName}} => Enginio company name'"></p>
            <p v-html="'{{offerCompanyIcon}} => Enginio company icon'"></p>
            <p v-html="'{{offerCompany11Icon}} => Enginio company 1:1 icon'"></p>
            <p v-html="'{{offerCompanyFaqUrl}} => intercom url to FAQ'"></p>
            <p v-html="'{{fullYear}} => current year => 2024'"></p>

            <br/>
            <div v-pre>
              <b>Usable values when sending emails from an Offer:</b>
              <p>{{user._id}} => user id</p>
              <p>{{user.name}} => user first name</p>
              <p>{{user.last_name}} => user last name</p>
              <p>{{code}} => code from clearon or</p>
            </div>
          </div>
      </slot>
    </modal>

    <modal
      v-if="uploadModal"
      :header="{ closeButton: true, text: 'Upload Asset' }"
      :overlay="{ clickable: false }"
      @close="uploadModal = false" size="xlarge">
      <slot>
        <div for="mediaTypeSelect">Select media type</div>
        <md-radio class="md-primary"
          value="Image"
          name="mediaTypeSelect"
          v-model="mediaTypeSelect">Image</md-radio>
        <md-radio class="md-primary"
          value="Video"
          name="mediaTypeSelect"
          v-model="mediaTypeSelect">Video</md-radio>
        <md-radio class="md-primary"
          value="Pdf"
          name="mediaTypeSelect"
          v-model="mediaTypeSelect">Pdf</md-radio>

        <Uploader
          role="Admin"
          :mediaType="mediaTypeSelect"
          entity="CompanyArbitrary"
          :entityId="company._id"
          :aspectWidth="NaN"
          :aspectHeight="NaN"
          @pickedFile="addAsset($event)"/>
      </slot>
    </modal>

    <modal
      v-if="openContentModal"
      :header="{ closeButton: true, text: 'Open Content' }"
      :overlay="{ clickable: false }"
      @close="openContentModal = false" size="xlarge">
      <slot>
        <h2>!! Important !!</h2>
        <div>When you click a content below, your current progress will be lost</div>

        <InputField
          v-model="query"
          placeholder="Find content..."
          trailingIcon="magnifyingGlass"
          clickableTrailingIcon
          @trailingIconClick="findAdminContents()"
          @enterKey="findAdminContents()"/>

        <div class="content-list-wrapper">
          <div
            class="content-list-item"
            v-for="content in contents"
            :key="content._id"
            @click="setContent(content)">
            {{ content.name }}
          </div>
        </div>
      </slot>
    </modal>

    <modal
      v-if="newContentModal"
      :header="{ closeButton: true, text: 'New Content' }"
      :overlay="{ clickable: false }"
      @close="newContentModal = false" size="xlarge">
      <slot>
        <div v-if="!includeCurrentContent">
          <h2>!! Important !!</h2>
          <div>When you create new content below, your current progress will be lost</div>
        </div>

        <InputField
          label="Content name"
          v-model="newContentName"/>

        <InputField
          label="Include current content"
          inputType="checkbox"
          v-model="includeCurrentContent"/>

        <button
          class="btn btn-activate"
          @click="addAdminContent">Add</button>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
// import vuePdf from 'vue-pdf';
import CKEditor from '../CKEditor/CKEditor.vue';
import Collapsable from '../Collapsable/Collapsable.vue';
import Uploader from '../Uploader/Uploader.vue';
import Content from './Content.vue';
import ContentText from './ContentText.vue';
import ContentTable from './ContentTable.vue';
import ContentImage from './ContentImage.vue';
import ContentVideo from './ContentVideo.vue';
import ContentIframe from './ContentIframe.vue';
import InputField from '../InputField/InputField.vue';
import ColorSelect from '../ColorSelect/ColorSelect.vue';
// import cssContent from '!!raw-loader!./Content.css';

export default {
  name: 'ContentCreator',
  components: {
    // vuePdf,
    draggable,
    CKEditor,
    Content,
    Collapsable,
    Uploader,
    ContentText,
    ContentTable,
    ContentImage,
    ContentVideo,
    ContentIframe,
    InputField,
    ColorSelect,
  },
  props: {
    /*value: {
      type: Array,
      required: false,
    },*/
    company: {
      type: Object,
      required: true,
    },
    contentId: {
      type: String,
      required: false,
    },
  },
  async mounted() {
    this.localContent = _cloneDeep(this.content);
    if (!this.localContent.rows) {
      this.localContent.backgroundColor = this.brandButtonColor;
      this.localContent.maxWidth = '350px';
      this.localContent.borderRadius = '5px';
      this.localContent.gridGap = '5px';
      this.localContent.margin = '5px';
      this.$set(this.localContent, 'rows', [{ backgroundColor: 'white', display: 'inline-table', cells: [] }]);
    }

    if (this.contentId) {
      this.query = this.contentId;
      await this.findAdminContents();
      this.setContent(this.contents[0]);
      this.query = '';
    }

    this.availableCells[1].backgroundColor = this.brandButtonColor;
    this.loadMedia();
  },
  computed: {
    ...mapGetters([
      'assets',
      'content',
      'contents',
    ]),
    brandButtonColor() {
      if (this.company.brand_button_color) {
        return this.company.brand_button_color;
      } else {
        return '#ea7801';
      }
    },
  },
  watch: {
    'content._id': {
      handler() {
        this.localContent = _cloneDeep(this.content);
        this.contentName = this.content.name;
        this.$emit('changeContent', this.content._id);
      },
      deep: true
    }
  },
  data() {
    return {
      newContentModal: false,
      newContentName: '',
      includeCurrentContent: true,
      addContentOpen: false,
      contentSettingsOpen: true,
      companyMediaOpen: true,
      contentName: '',
      displayOptions: ['inline-table', 'grid', 'flex'],
      gridTemplateColumnsOptions: ['1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr 1fr', 'repeat(auto-fit, minmax(100px, 1fr))', 'repeat(auto-fit, minmax(150px, 1fr))', 'repeat(auto-fit, minmax(300px, 1fr))'],
      gridGapOptions: ['0px', '5px', '10px', '15px'],
      borderRadiusOptions: ['0px', '5px', '10px', '15px', '50%'],
      maxWidthOptions: ['', '350px', '480px', '786px', '1000px'],
      localContent: {},
      templates: {
        landing: {
          name: 'Landing page',
          symbol: 'rocket',
          backgroundColor: 'white',
          maxWidth: '786px',
          borderRadius: '0px',
          gridGap: '0px',
          margin: '5px',
          rows: [
            {
              backgroundColor: 'white',
              display: 'inline-table',
              gridGap: '5px',
              padding: '10px',
              cells: [
                { type: "image", media: { url: "https://via.placeholder.com/1000" }, alt: "Placeholder image" },
                { type: "text", text: "<h2>This is a Title<h2>" },
                { type: "text", text: "<h2>This is a Title<h2>" },
              ]
            },
            {
              backgroundColor: 'white',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
              gridGap: '5px',
              padding: '10px',
              cells: [
                { type: "text", text: "This is a text" },
                { type: "text", text: "" },
              ]
            },
          ]
        },
        email: {
          name: 'Enginio_cashback_winner_SE_24',
          symbol: 'email',
          backgroundColor: '#ea7801',
          maxWidth: '480px',
          borderRadius: '10px',
          gridGap: '0px',
          marginIcon: { media: { url: "https://www.enginio.io/se/api/nar/file/Enginio_logotype_horizontal_neg.png" }, alt: "Placeholder image", padding: '10px 0' },
          margin: '16px 10px',
          rows: [
            {
              backgroundColor: 'white',
              display: 'inline-table',
              padding: '26px 16px 16px 16px',
              gridGap: '5px',
              cells: [
                { type: "text", text: "<h3>Congratulations {{user.name}}!</h3>", padding: '0 0 10px 0' },
                { type: "image", media: { url: "https://via.placeholder.com/480" }, alt: "Placeholder image", padding: '10px 0' },
                { type: "text", text: "Now you just have to <b>sit back</b> and wait for us to take care of the rest!", padding: '10px 0' },
                { type: "text", text: "In the meantime, if you are bored, click the button below to see our other campaigns", padding: '10px 0' },
                { type: 'button', text: 'click me!', url: 'https://www.enginio.io/se', margin: '10px 0 0 0' },
              ]
            }
          ]
        },
      },
      availableCells: [
        { type: 'text', text: 'Text' },
        { type: 'button', text: 'click me!', margin: '0px', backgroundColor: '#ea7801' },
        /*{ type: 'table', table: [['A', 'B', 'C'], ['1', '2', '3']] },*/
        { type: 'image', media: { url: 'https://via.placeholder.com/450' }, alt: 'Placeholder image' },
        { type: 'video', media: { url: 'https://www.youtube.com/embed/dQw4w9WgXcQ' }, alt: 'Rick Astley - Never Gonna Give You Up' },
        { type: 'iframe', url: 'https://www.youtube.com/embed/dQw4w9WgXcQ', alt: 'Rick Astley - Never Gonna Give You Up' },
      ],
      dragAsset: {},
      trashCells: [],
      openTrashcan: false,
      openTemplateModal: false,
      contentEdit: true,
      helpModal: false,
      helpModalMargin: '10px',
      helpModalPadding: '5px',
      helpModalBorderRadius: '0px',
      uploadModal: false,
      openContentModal: false,
      query: '',
      mediaTypeSelect: 'Image',
      deviceSelected: 'mobile',
      editTheRow: null,
      editTheCell: null,
      editTheRowIndex: null,
      editTheCellIndex: null,
      assetOrigin: window.origin !== undefined ? window.origin : window.location.origin,
    }
  },
  methods: {
    async loadMedia() {
      await this.$store.dispatch('getAdminCompanyAssets', { entityId: this.company._id });
    },
    async addAdminContent() {
      const content = this.includeCurrentContent ? this.localContent : {};
      await this.$store.dispatch('addAdminContent', {
        ...content,
        name: this.newContentName,
      });
    },
    async saveAdminContent() {
      await this.$store.dispatch('saveAdminContent', {
        ...this.localContent,
        entityId: this.content._id,
        name: this.contentName,
      });
      this.setAlert('Content saved!');
    },
    addRow() {
      this.localContent.rows.push({
        backgroundColor: 'white',
        display: 'inline-table',
        cells: [],
      });
    },
    handleClone(original) {
      return _cloneDeep(original);
    },
    editRow(row) {
      this.editTheRowIndex = row;
      this.editTheCellIndex = null;

      this.editTheRow = this.localContent.rows[row];
      this.editTheCell = null;
    },
    editCell({ row, cell}) {
      this.editTheRowIndex = row;
      this.editTheCellIndex = cell;
      this.editTheCell = this.localContent.rows[row].cells[cell];
      this.editTheRow = null;
    },
    resetEdit() {
      this.editTheRow = null;
      this.editTheCell = null;
      this.editTheRowIndex = null;
      this.editTheCellIndex = null;
    },
    removeRow() {
      this.localContent.rows.splice(this.editTheRowIndex, 1);
      this.resetEdit();
    },
    selectColor(color, row, cell) {
      if (cell) {
        this.$set(this.editTheCell, 'backgroundColor', color);
      } else if (row) {
        this.$set(this.editTheRow, 'backgroundColor', color);
      } else {
        this.$set(this.localContent, 'backgroundColor', color);
      }
    },
    async generateHtml() {
      const response = await axios.put(`/api/admin/contents/${this.content._id}/export-html`);
      const { html } = response.data;
      return html;
    },
    getImageUrl(media) {
      const { url, fileType, id } = media;
      // if localhost, then the url is already correct, otherwise the url starts with /media/...original.[extension]
      // this makes it 
      return (this.windowOrigin.includes('localhost') ? '' : this.assetOrigin) + url;
    },
    async exportHtml() {
      const htmlContent = await this.generateHtml();

      const blob = new Blob([htmlContent], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${this.contentName || 'your_page'}.html`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    getRowBorderRadius(rowIndex) {
      if (!this.localContent.gridGap || this.localContent.gridGap != '0px' || this.localContent.rows.length == 1) {
        return this.localContent.borderRadius;
      } else if (rowIndex === 0) {
        return `${this.localContent.borderRadius} ${this.localContent.borderRadius} 0 0`;
      } else if (rowIndex === this.localContent.rows.length - 1) {
        return `0 0 ${this.localContent.borderRadius} ${this.localContent.borderRadius}`;
      } else {
        return '';
      }
    },
    chooseTemplate(key) {
      this.localContent = this.templates[key];
    },
    dragStart(asset) {
      this.dragAsset = JSON.parse(JSON.stringify(asset));
    },
    drop({ row, cell }) {
      if (row && cell) {
        this.$set(this.localContent.rows[row].cells[cell], 'media', this.dragAsset);  
      } else {
        this.$set(this.editTheCell, 'media', this.dragAsset);
      }
    },
    async copyHtml() {
      this.copyText(await this.generateHtml(), 'HTML');
    },
    copyText(text, type) {
      navigator.clipboard.writeText(text).then(() => {
        this.setAlert(`Copied ${type} to clipboard`);
      }).catch(err => {
        this.setAlert(`Failed to copy ${type} to clipboard`);
      });
    },
    addAsset(asset) {
      this.$store.commit('ADD_ASSET', asset);
    },
    setContent(content) {
      this.resetEdit();
      this.$store.commit('CONTENT', content);
    },
    findAdminContents() {
      return this.$store.dispatch('findAdminContents', {
        entityId: this.company._id,
        query: this.query,
      });
    },
  }
}
</script>

<style lang="scss">
@import './ContentCreator.scss';
</style>
