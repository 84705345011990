<template>
  <div class="entity-manager-container">
    <router-link
      v-if="routeName != ''"
      class="entity-route"
      :to="{ name: routeName }">
      Manage {{vuexEntityType}}s
    </router-link>
    <div class="entity-manager-wrapper">
      <div
        class="entity-manager-entity"
        v-for="(entity, index) in entities"
        :key="entity._id">
        <div class="entity-manager-entity-wrapper">
          <div class="entity-manager-entity-name">
            {{ index + 1 }}. {{ getCombinedText(entity) }}
          </div>
        </div>
        <div
          class="btn entity-manager-icon"
          @click="removeEntity(index)">
          <iconComponent symbol="trashcan" />
        </div>
      </div>
    </div>
    <div v-if="addNew" style="margin-top: 10px">
      <AutoComplete
        :value="entitySearch"
        :options="modalEntities"
        :required="true"
        :is-async="true"
        unique-id="change"
        :title="`Add another ${vuexEntityType}`"
        :placeholder="`Find ${vuexEntityType} by name`"
        resultPosition="relative"
        @input="findModalEntities($event)"
        @selected="pickEntity($event)"/>
    </div>
    <div
      class="btn btn-activate btn-width"
      @click="addNew = !addNew">
      <iconComponent symbol="plusMath" fill="white" stroke="white" />Add {{ vuexEntityType }} to list
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AutoComplete from '../AutoComplete/AutoComplete.vue';

export default {
  name: 'Multichoice',
  components: {
    AutoComplete,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    entities: {
      type: Array,
      required: false,
      default: () => [],
    },
    routeName: {
      type: String,
      required: false,
      default: '',
    },
    entityType: {
      type: String,
      required: true,
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayFields: {
      type: Array,
      required: true,
      default: () => ['name'],
    }
  },
  mounted() {
    // this.findModalEntities('');
  },
  computed: {
    ...mapGetters('parentCompanies', ['modalParentCompanies']),
    ...mapGetters('users', { users: 'modalUsers' }),
    ...mapGetters('modalCompanies', { goodiebags: 'modalGoodiebags' }),
    ...mapGetters('pvCampaigns', { compaigns: 'modalPvCampaigns' }),
    ...mapGetters('pvRetailers', { retailers: 'modalPvRetailers' }),
    userType() {
      return this.admin ? 'Admin' : 'Company';
    },
    namespace() {
      let namespace = '';
      if (this.entityType === 'ParentCompany') {
        namespace = 'parentCompanies/';
      } else if (this.entityType.includes('User')) {
        namespace = 'users/';
      } else if (this.entityType.includes('Game')) {
        namespace = 'games/';
      } else if (this.entityType.includes('PVActivation')) {
        namespace = 'pvActivations/';
      } else if (this.entityType.includes('PVCampaign')) {
        namespace = 'pvCampaigns/';
      } else if (this.entityType.includes('PVCompany')) {
        namespace = 'pvCompanies/';
      } else if (this.entityType.includes('PVChain')) {
        namespace = 'pvChains/';
      } else if (this.entityType.includes('PVRetailer')) {
        namespace = 'pvRetailers/';
      } else if (this.entityType.includes('PVStore')) {
        namespace = 'pvStores/';
      }
      return namespace;
    },
    vuexEntityType() {
      if (this.entityType.includes('Game')) {
        return 'Game';
      } else if (this.entityType.includes('PV')) {
        return this.entityType.slice(2);
      } else {
        return this.entityType;
      }
    },
    modalEntities() {
      return this[this.vuexEntityType.toLowerCase() + 's'];
    },
  },
  data() {
    return {
      selected: '',
      addNew: false,
      newEntity: 'choose',
      entitySearch: '',
    };
  },
  methods: {
    findModalEntities(query) {
      this.$store.dispatch(`${this.namespace}find${this.userType}Modal${this.entityType}`, { query, number: 10 }).then(() => {
        // this.loadedPage = true;
      });
    },
    pickEntity(entity) {
      if (!entity.value) {
        return;
      }
      this.$emit('change', [...this.entities, { _id: entity.value, name: entity.text }]);
    },
    removeEntity(index) {
      const updatedEntities = [...this.entities];
      updatedEntities.splice(index, 1);
      this.$emit('change', updatedEntities);
    },
    getCombinedText(entity) {
      return this.displayFields.map(field => entity[field]).filter(Boolean).join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Multichoice.scss';

.btn-width {
  width: 225px;
  float: center;
}
</style>
