<template>
  <div v-if="user">
    <div class="dashboard-container">
      <div class="dashboard-breadcrumbs">
        <span class="breadcrumb-link">Users</span>
        <span class="breadcrumb-separator">></span>
        <span class="breadcrumb-current">{{ user.name }} {{ user.last_name }}</span>
      </div>

      <div class="dashboard-content">
        <div class="top-row">
          <div class="user-card">
            <h1>{{ user.name }} {{ user.last_name }}</h1>
            <!-- <div class="tabs-container">
              <div class="tabs-indicator"></div>
              <button
                v-for="(tab, index) in tabs"
                class="tab-button"
                :key="tab"
                :class="{ 'tab-button--selected': index === tabIndex }"
                @click="tabIndex = index"
              >
                {{ tab }}
              </button>
            </div> -->
            <ul class="user-info">
              <li>Email:<span>{{ user.email }}</span></li>
              <li>Phone:<span>{{ user.phoneNr }}</span></li>
              <li>Address:<span>{{ user.address || '-' }}</span></li>
              <li>Postal Code:<span>{{ user.postalCode || '-' }}</span></li>
              <li>City:<span>{{ user.city || '-' }}</span></li>
              <li>Country:<span>{{ user.country || '-' }}</span></li>
              <li>Joined:<span>{{ formatTimestamp(user.joinedAt) }}</span></li>
            </ul>
          </div>

          <div class="user-card">
            <h2>Activations ({{ activations.length }})</h2>
            <div class="user-activations">
              <PartnerTable
                :data="activations"
                :fields="activationFields"
                :minimumRows="5"
                :selectedIndex="selectedActivationIndex"
                @clickItem="viewActivationProgress($event)"
              >
                <template v-slot:column-image="{ item }">
                  <img :src="item.image" />
                </template>
                <template v-slot:column-name="{ item }">
                  <span class="user-activation-name">{{ item.name }}</span>
                </template>
                <template v-slot:column-registeredAt="{ item }">
                  {{ formatTimestamp(item.registeredAt) }}
                </template>
                <template v-slot:column-status="{ item }">
                  <span class="user-activation-status" :class="getStatusClass(item.status)">
                    {{ getStatusLabel(item.status) }}
                  </span>
                </template>
              </PartnerTable>
            </div>
          </div>
        </div>

        <UserActivationEditor
          v-if="activation"
          :activation="selectedActivation"
          :receipt="selectedReceipt"
          :mediaHost="pvHost"
          :key="activation.id"
          :selectedStep="this.$route.query.step"
          @selectStep="handleSelectStep"
          @changeActivation="handleChangeActivation"
          @changeReceipt="handleChangeReceipt"
          @saveActivation="updateActivation"
          @confirmReceipt="handleConfirmReceipt"
          @declineReceipt="handleDeclineReceipt"
        />
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';

import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import PartnerTable from '@/stories/components/PartnerTable/PartnerTable.vue';
import UserActivationEditor from '@/stories/components/UserActivationEditor/UserActivationEditor.vue';

export default {
  name: 'CompanyUser',
  mixins: [BeforeEnterMixin],
  components: {
    PartnerTable,
    UserActivationEditor,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      user: null,
      activations: [],
      tabIndex: 0,

      activation: null,
      receipt: null,
      editActivation: {},
      editReceipt: {},

      pvHost:
        process.env.NODE_ENV !== 'production'
          ? 'http://localhost:8003/api/nar/file'
          : 'https://purchasevalidation.com/api/nar/file',
      allowOrigin:
        process.env.NODE_ENV !== 'production'
          ? 'http://localhost:8080'
          : this.windowOrigin,
    };
  },
  async mounted() {
    await this.$store.dispatch('getPickedCompany');
    await this.getData();
    // if (this.user) {
    //   this.getActivation();
    // }
  },
  computed: {
    ...mapGetters(['company']),
    activationId() {
      return this.activation?.id ?? null;
    },
    receiptId() {
      return this.activation?.receiptId ?? null;
    },
    activationFields() {
      return [
        {
          key: 'image',
          label: ''
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          registeredAt: 'registeredAt',
          label: 'Registered',
        },
        {
          key: 'status',
          label: 'Status'
        }
      ]
    },
    selectedActivationIndex() {
      if (!this.$route.query.activation) return null;

      return this.activations.findIndex(activation => activation.id === this.$route.query.activation);
    },
    selectedActivation() {
      if (!this.activation || this.$route.query.activation !== this.activation?.id) return null;

      return {
        ...this.activation,
        steps: this.activation.steps.map(step => ({
          ...step,
          icon: this.getStepIcon(step),
        })),
      }
    },
    selectedReceipt() {
      if (!this.receipt || this.$route.query.receipt !== this.receipt?._id) return null;

      return this.receipt;
    },
    tabs() {
      return ['Information', 'Prizes & Payouts', 'Messages', 'Notes'];
    },
  },
  methods: {
    determineStep() {
      if (!this.activation?.steps) return;

      const foundIndex = this.activation.steps.findLastIndex(step => step.status === 'completed');
      const newIndex = Math.max(0, Math.min(foundIndex + 1, this.activation.steps.length - 1))

      const query = this.$route.query;
      this.$router.replace({ query: { ...query, step: this.activation.steps[newIndex].id } });
    },
    async getData() {
      try {
        this.loading = true;
        const response = await axios.get(`/api/c/users/${this.userId}`);
        this.user = response.data.user;
        this.activations = response.data.activations;

        if (this.user) {
          this.getActivation();
        }
      } catch (error) {
        console.log(error);
        this.error = error.message ?? 'Failed to load data';
      } finally {
        this.loading = false;
      }
    },
    async getActivation() {
      if (!this.$route.query.activation || !this.$route.query.receipt) return;

      try {
        const { activation: activationId, receipt: receiptId } = this.$route.query;
        const response = await axios.get(`/api/c/users/${this.userId}/activations/${activationId}`, { params: { receiptId } });
        const { receipt, ...activation } = response.data || {};
        this.activation = activation;
        this.receipt = receipt;
      } catch (error) {
        console.log(error);
      }
    },
    getStatusClass(status) {
      return {
        'user-activation-status--error': status === 'error',
        'user-activation-status--completed': status === 'completed',
        'user-activation-status--in-progress': status === 'in-progress',
      }
    },
    getStatusLabel(status) {
      switch (status) {
        case 'error':
          return 'Error';
        case 'completed':
          return 'Completed';
        case 'in-progress':
          return 'In progress';
        default:
          return 'Unknown';
      }
    },
    getStepIcon(step) {
      switch (step.id) {
        case 'PLACEHOLDER_CREATED':
          return require('@/stories/assets/module-icon-activation.png');
        case 'TERMS_CREATED':
          return require('@/stories/assets/module-icon-terms.png');
        case 'RECEIPT_CREATED':
          return require('@/stories/assets/module-icon-style.png');
        case 'RECEIPT_CONFIRMED':
          return require('@/stories/assets/module-icon-receipt.png');
        case 'REGISTRATION_CONFIRMED':
          return require('@/stories/assets/module-icon-product.png');
        case 'SURVEY_CONFIRMED':
          return require('@/stories/assets/module-icon-survey.png');
        case 'CASHBACK_CONFIRMED':
          return require('@/stories/assets/module-icon-cashback.png');
        case 'CTA_DONE':
          return require('@/stories/assets/module-icon-confirm.png');
        case 'email':
          return require('@/stories/assets/module-icon-email.png');
        case 'return-product':
          return require('@/stories/assets/module-icon-return.png');
        default: {
          return '';
        }
      }
    },
    handleChangeActivation(data) {
      this.editActivation = { ...this.editActivation, ...data };
    },
    handleChangeReceipt(data) {
      this.editReceipt = { ...this.editReceipt, ...data };
    },
    handleSelectStep(step) {
      this.$router.replace({ query: { activation: this.activation.id, receipt: this.activation.receiptId, step: step.id } });
    },
    formatTimestamp(timestamp) {
      return moment.unix(timestamp).format('DD/MM/YYYY');
    },
    async updateActivation() {
      if (!this.activationId || (Object.keys(this.editActivation).length === 0)) return;

      try {
        const formData = new FormData();
        formData.append('activation', JSON.stringify(this.editActivation));

        const response = await axios.put(`/api/c/companies/activations/${this.activationId}/pv`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Use-Original-Filename': 'true',
          }
        });

        this.setAlert('Activation updated', 1);
        this.editActivation = {};
        const { receipt, ...activation } = response.data?.activation || {};
        if (activation) {
          this.activation = { ...this.activation, ...activation };
        }
        if (receipt) {
          this.receipt = { ...this.receipt, ...receipt };
        }
      } catch (error) {
        this.setAlert('An error occurred, could not publish activation', 1);
      }
    },
    async updateReceipt() {
      if (!this.receiptId || (Object.keys(this.editReceipt).length === 0)) return;

      try {
        await axios.put(`/api/c/companies/activations/${this.activationId}/receipts/${this.receiptId}`, this.editReceipt);
        this.setAlert('Receipt updated', 1);
        this.editReceipt = {};
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
    // ToDo: Find better solution
    handleConfirmReceipt() {
      this.editReceipt = { ...this.editReceipt, confirmed_receipt: true };
      this.updateReceipt();
    },
    // ToDo: Find better solution
    handleDeclineReceipt() {
      this.editReceipt = { ...this.editReceipt, confirmed_receipt: false };
      this.updateReceipt();
    },
    viewActivationProgress(activation) {
      this.$router.replace({ query: { activation: activation.id, receipt: activation.receiptId } });
    },
  },
  watch: {
    '$route.query.activation': {
      handler: 'getActivation',
      immediate: false,
    },
    activation: {
      handler: 'determineStep',
      immediate: false,
    },
    editActivation() {
      console.log('editActivation', this.editActivation);
    },
    editReceipt() {
      console.log('editReceipt', this.editReceipt);
    },
  }
};
</script>

<style lang='scss' scoped>
@import '../assets/scss/pages/CompanyDashboard';

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px 50px;
  min-width: 1472px;
}

.top-row {
  display: flex;
  gap: 16px;
}

.user-card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 646px;
  height: 416px;
  padding: 16px;

  overflow: hidden;
  border-radius: 8px;
  background-color: white;
}

.user-activations {
  display: flex;
  overflow: hidden auto;
  border-radius: 8px;
  border: 1px solid #E1E3E5;

  table {
    flex: 1;
  }

  th {
    border-top: 0;
  }

  tr:not(:last-child) td {
    border-bottom: 0;
  }

  .user-activation-name {
    font-weight: 700;
  }

  .user-activation-status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 0 8px;
    border-radius: 10px;
    background-color: #AAAAAA;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #202223;
  }

  .user-activation-status--error {
    background-color: #FCC9C9;
  }
  .user-activation-status--in-progress {
    background-color: #EBDF71;
  }
  .user-activation-status--completed {
    background-color: #AEE9D1;
  }
}

.tabs-container {
  position: relative;
  display: flex;
  gap: 24px;
  padding: 16px 16px 0 16px;

  .tab-button {
    display: flex;
    flex-direction: column;
    height: 27px;
    padding: 0;
    border: 0;
    background-color: transparent;
    z-index: 1;

    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: #111111;
  }

  .tab-button--selected {
    font-weight: 700;
    color: $brand-yellow;
    border-bottom: 1px solid $brand-yellow;
  }

  .tabs-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #F2F2F2;
  }
}

.user-info {
  list-style: none;
  padding: 16px;

  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;

  li:not(:last-child) {
    margin-bottom: 7px;
  }

  li span {
    margin: 0 8px;
  }
}
</style>