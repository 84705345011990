/* eslint-disable no-shadow, no-param-reassign */
import retailerApi from '../../api/pvRetailer.api';

// initial state
const state = {
  retailer: {},

  retailerQuery: '',
  retailers: [],

  modalPvRetailers: [],
};

// getters
const getters = {
  retailer: state => state.retailer,

  retailerQuery: state => state.retailerQuery,
  retailers: state => state.retailers,

  modalPvRetailers: state => state.modalPvRetailers,
};

// actions
const actions = {
  // ADMIN
  findAdminPVRetailer({ commit }, payload) {
    commit('RETAILER_STATUS', 'LOADING');
    return retailerApi.findAdminPVRetailer(payload)
      .then((response) => {
        commit('RETAILERS', response.retailers);
        commit('RETAILER_STATUS', 'FINISHED');
      });
  },

  findAdminModalPVRetailer({ commit }, payload) {
    commit('RETAILER_STATUS', 'LOADING');
    return retailerApi.findAdminPVRetailer(payload)
      .then((response) => {
        commit('MODAL_RETAILERS', response.retailers);
        commit('RETAILER_STATUS', 'FINISHED');
      });
  },

  addAdminPVRetailer({ commit }, payload) {
    commit('RETAILER_STATUS', 'LOADING');
    return retailerApi.addAdminPVRetailer(payload)
      .then((response) => {
        commit('ADD_RETAILER', response.retailer);
        commit('RETAILER_STATUS', 'FINISHED');
      });
  },

  importAdminRetailers({ commit }, payload) {
    commit('CHAIN_STATUS', 'LOADING');
    return retailerApi.importAdminRetailers(payload)
      .then((response) => {
        const { jsonMessage } = response;
        const { newRetailers } = jsonMessage;
        for (let i = 0; i < newRetailers.length; i += 1) {
          commit('ADD_RETAILER', newRetailers[i]);
        }
        commit('CHAIN_STATUS', 'FINISHED');
        return response;
      });
  },

  saveAdminPVRetailer({ commit }, payload) {
    commit('RETAILER_STATUS', 'LOADING');
    return retailerApi.saveAdminPVRetailer(payload)
      .then((response) => {
        commit('SAVE_RETAILER', response.retailer);
        commit('RETAILER_STATUS', 'FINISHED');
      });
  },

  getAdminPVRetailer({ commit }, payload) {
    commit('RETAILER_STATUS', 'LOADING');
    return retailerApi.getAdminPVRetailer(payload)
      .then((response) => {
        commit('RETAILER', response.retailer);
        commit('RETAILER_STATUS', 'FINISHED');
      });
  },

  deleteAdminPVRetailer({ commit }, payload) {
    commit('RETAILER_STATUS', 'LOADING');
    return retailerApi.deleteAdminPVRetailer(payload)
      .then(() => {
        commit('DELETE_RETAILER', payload.entityId);
        commit('RETAILER_STATUS', 'FINISHED');
        return;
      });
  },

  // COMPANY
  findCompanyPVRetailer({ commit }, payload) {
    commit('RETAILER_STATUS', 'LOADING');
    return retailerApi.findCompanyPVRetailer(payload)
      .then((response) => {
        commit('RETAILERS', response.retailers);
        commit('RETAILER_STATUS', 'FINISHED');
      });
  },

  findCompanyModalPVRetailer({ commit }, payload) {
    commit('RETAILER_STATUS', 'LOADING');
    return retailerApi.findCompanyPVRetailer(payload)
      .then((response) => {
        commit('MODAL_RETAILERS', response.retailers);
        commit('RETAILER_STATUS', 'FINISHED');
      });
  },
};

// mutations
const mutations = {
  RETAILER(state, retailer) {
    state.retailer = retailer;
  },
  RETAILERS(state, retailers) {
    state.retailers = retailers;
  },
  ADD_RETAILER(state, retailer) {
    state.retailers.unshift(retailer);
  },

  RETAILER_QUERY(state, retailerQuery) {
    state.retailerQuery = retailerQuery;
  },
  SAVE_RETAILER(state, retailer) {
    state.retailer = retailer;
  },
  DELETE_RETAILER(state) {
    state.retailer = {};
  },
  RETAILER_STATUS(state, status) {
    state.retailerStatus = status;
  },
  MODAL_RETAILERS(state, retailers) {
    state.modalPvRetailers = [];
    for (let i = 0; i < retailers.length; i += 1) {
      state.modalPvRetailers.push({
        value: retailers[i]._id,
        text: retailers[i].name,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
