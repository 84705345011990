<template>
  <div class="home-data">
    <div class="--header">
      <div
           class="gradient-header show-desktop"
           :style="`background-image: url(${require('../assets/images/test_swirls_01_wALPHA_10.png')});`">
      </div>

      <div class="-menu-back show-desktop">
        <h1>{{ transl.HomeData.privacyfriendly }}</h1>
      </div>

      <div class="-menu-back show-mobile-and-tablet">
        <h3 style="margin: 17px 25px;">{{ transl.HomeData.privacyfriendly }}</h3>
      </div>
    </div>

    <div class="data-wrapper">
      <div class="left-card">
        <div
             class="data-image"
             :style="`background-image: url(${require('../assets/images/Integrity_header.jpg')}); background-position: 0 49%;`">
          <Hero
                class="absolute-stamp"
                type="Integrity_badge_Light" />
        </div>

        <div v-html="transl.HomeData.homedatatext"></div>
      </div>

      <div class="right-card">
        <div class="--container">
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.cookies' })">
            <div class="button-text">Cookies policy</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.visitors' })">
            <div class="button-text">Privacy Policy - Visitors</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.members' })">
            <div class="button-text">Privacy Policy - Members</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.participants' })">
            <div class="button-text">Privacy Policy - Participants</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <div
               class="data-link"
               @click="$router.push({ name: 'HomeData.terms' })">
            <div class="button-text">Terms and conditions</div>
            <iconComponent stroke="#999999" width="16px" fill="#999999" symbol="forward" />
          </div>
          <!--<div
             class="data-link"
             @click="$router.push({ name: 'HomeData.companies' })">
          Companies at Enginio
          <iconComponent symbol="forward" />
        </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import Hero from '../stories/components/Hero/Hero.vue';

export default {
  name: 'HomeData',
  components: { Hero },
  mixins: [BeforeEnterMixin],
  mounted() {
    localStorage.removeItem('dashboard');
    this.setCompany({});
    this.setPageTitle('A privacy friendly platform');
  },
  computed: {
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations({
      setCompany: 'COMPANY',
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/scss/pages/HomeData";
</style>
