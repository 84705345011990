<template>
  <div
    v-if="loadedPage"
    class="participation-list">

    <div
      class="the-list">
      <md-table>
        <md-table-toolbar class="list-toolbar">
          <div>
            <h1 v-if="participationsTotal" class="md-title">{{ participationsTotal }} Participations</h1>
            <h1 v-else class="md-title">{{ participations.length >= 50 ? '> 50' : participations.length }} Participations</h1>
          </div>
          <InputField
            v-model="search"
            placeholder="Find participation..."
            trailingIcon="magnifyingGlass"
            clickableTrailingIcon
            @trailingIconClick="findAdminAllParticipations()"
            @enterKey="findAdminAllParticipations()"/>

          <div
            class="column-picker"
            @focus="gameOptions = true"
            @blur="gameOptions = false">
            <button
              class="btn btn-activate"
              @click="gameOptions = !gameOptions">
              Games
            </button>
            <div
              v-show="gameOptions"
              class="column-options">
              <InputField
                v-model="gameType"
                class="column-search"
                inputType="select"
                :selectOptions="gameTypes"
                @input="findAdminGame()"/>
              <InputField
                v-model="actSearch"
                placeholder="Find game..."
                class="column-search"
                @input="findAdminGame()"/>
              <div
                class="column-option"
                v-for="(game, index) in gamePicker"
                :class="{ 'active-column-option': game.active }"
                :key="`column-option-${index}-${game.name}`"
                @click="activateGameFilter(game)">
                {{ game.company.name }} - {{ game.name }}
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="filterOptions = true"
            @blur="filterOptions = false">
            <button
              class="btn btn-activate"
              @click="filterOptions = !filterOptions">
              Filters
            </button>
            <div
              v-show="filterOptions"
              class="column-options">
              <div
                class="column-option"
                v-for="filter in filters"
                :class="{ 'active-column-option': filter.active }"
                :key="`column-option-${filter.id}`">
                <div @click="filter.func ? filter.func() : filter.active = !filter.active">
                  {{ filter.name }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="columnOptions = true"
            @blur="columnOptions = false">
            <button
              class="btn btn-activate"
              @click="columnOptions = !columnOptions">
              Columns
            </button>
            <div
              v-show="columnOptions"
              class="column-options">
              <div
                class="column-option"
                v-for="column in actualColumns"
                :class="{ 'active-column-option': column.active }"
                :key="`column-option-${column.title}`"
                @click="column.active = !column.active">
                {{ column.title }}
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="statOptions = true"
            @blur="statOptions = false">
            <button
              class="btn btn-activate"
              :class="{ 'btn-disabled': !gameId }"
              @click="statOptions = !statOptions">
              Stats
            </button>
            <div
              v-show="statOptions"
              class="column-options">
              <div class="column-option">
                NOTE: the search will not be applied to any of these lists (yet)
              </div>
              <div class="column-option">
                <button class="btn btn-activate" @click="showListModal = true">Get List</button>
              </div>
              <div v-if="admin" class="column-option">
                <button class="btn btn-activate" @click="show3rdPartyListModal = true">Get 3rd party List</button>
              </div>
              <div class="column-option">
                <button class="btn btn-activate" @click="showStatsModal = true">Get Stats</button>
              </div>
              <div class="column-option">
                <button class="btn btn-activate" @click="showCTAModal = true">Get CTA's</button>
              </div>
            </div>
          </div>
        </md-table-toolbar>
        <!--style="position: sticky; top: 0; z-index: 1;" dosnt work..-->
        <md-table-row>
          <md-table-head
            v-for="column in activeColumns"
            :key="`title-${column.title}`">
            {{ column.title }}
            <span
              v-if="column.sort"
              class="clickable"
              @click="toggleSort(column)">{{ activeSort == column.db ? activeSortType : 'sort' }}</span>
          </md-table-head>
        </md-table-row>
        <md-table-row
          v-for="participation in participations"
          :key="participation._id">
          <md-table-cell
            v-for="column in activeColumns"
            :key="`row-${column.title}`">
            <div
              v-if="column.title == 'Buttons'"
              class="btn-wrapper">
              <router-link
                class="btn btn-activate btn-small"
                :to="{ name: `${userType}GameParticipation`, params: { participationId: participation._id } }">Arrows</router-link>
              <a
                class="btn btn-transparent btn-small"
                :href="`${windowOrigin}/admin/participations/${participation._id}`"
                target="_blank">Tab</a>
            </div>
            <div v-else-if="!column.exclude">
              {{ getValue(participation, column) }}
              <span v-if="column.edit" @click="openEditModal(participation, column.db)">
                <svg style="vertical-align: top; cursor: pointer;" width="12px" height="12px" viewBox="0 0 24 24" id="_24x24_On_Light_Edit" data-name="24x24/On Light/Edit" xmlns="http://www.w3.org/2000/svg">
                  <rect id="view-box" width="24" height="24" fill="none"/>
                  <path id="Shape" d="M.75,17.5A.751.751,0,0,1,0,16.75V12.569a.755.755,0,0,1,.22-.53L11.461.8a2.72,2.72,0,0,1,3.848,0L16.7,2.191a2.72,2.72,0,0,1,0,3.848L5.462,17.28a.747.747,0,0,1-.531.22ZM1.5,12.879V16h3.12l7.91-7.91L9.41,4.97ZM13.591,7.03l2.051-2.051a1.223,1.223,0,0,0,0-1.727L14.249,1.858a1.222,1.222,0,0,0-1.727,0L10.47,3.91Z" transform="translate(3.25 3.25)" fill="#141124"/>
                </svg>
              </span>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <!--<md-table-row>-->
      <div class="pagination">
        <button
          :class="{ 'btn-disabled': first == 0 }"
          class="btn btn-activate"
          @click="paginate(-1)">
          Previous
        </button>
        <div style="align-self: center;">Page {{ first == 0 ? '1' : (first / number)+1 }}</div>
        <button
          :class="{ 'btn-disabled': participations.length < 50 }"
          class="btn btn-activate"
          @click="paginate(1)">
          Next
        </button>
      </div>
    </div>

    <modal
      v-if="editModal"
      size="xlarge"
      modalType="fullscreen"
      @close="editModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Edit participation data' }">
      <slot>
        <div>
          <span v-if="editKey == 'points_balance'">Change the value, either by: 100 or 80+20 or 110-10 </span>
          <InputField
            v-model="editValue"
            @enterKey="updateParticipation()"/>
        </div>
        <div>
          <button
            class="btn btn-activate"
            @click="updateParticipation()">
            Update
          </button>
          <button
            class="btn btn-transparent"
            @click="editModal = false">
            Close
          </button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="showListModal"
      size="xlarge"
      modalType="fullscreen"
      @close="showListModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Get List' }">
      <slot>
        <h3>Filters</h3>
        <md-datepicker
          v-model="statCreatedStart">
          <label>Created Start</label>
        </md-datepicker>
        <md-datepicker
          v-model="statCreatedEnd">
          <label>Created End</label>
        </md-datepicker>
        <md-field>
          <label for="cta_done">cta_done?</label>
          <md-select
            v-model="ctaDone">
            <md-option value="all">Both</md-option>
            <md-option :value="true">cta_done == true</md-option>
            <md-option :value="false">cta_done == false</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label for="administrated">administrated?</label>
          <md-select
            v-model="administrated">
            <md-option value="all">Both</md-option>
            <md-option :value="true">administrated == true</md-option>
            <md-option :value="false">administrated == false</md-option>
          </md-select>
        </md-field>
        <h3>Change selection</h3>
        <md-field>
          <label for="administrated">Set Administrated</label>
          <md-select
            v-model="setAdministrated">
            <md-option value="all">Set all</md-option>
            <md-option value="cta_done">Set all (cta_done == true)</md-option>
            <md-option :value="false">Don't set</md-option>
          </md-select>
        </md-field>
        <div>
          <button class="btn btn-activate" @click="getList()">Download List</button>
          <button class="btn btn-transparent" @click="showListModal = false">Close</button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="show3rdPartyListModal"
      size="xlarge"
      modalType="fullscreen"
      @close="show3rdPartyListModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Get 3rd party List' }">
      <slot>
        <div style="text-align: left;">
          <h3>Important!</h3>
          <p>When you download this list, the following will happen:</p>
          <ul>
            <li>All participations will be cta_done == true</li>
            <li>All participations will be administrated == false</li>
            <li>The participations you get will be set to administrated = true</li>
          </ul>
          <p>All of this means that the next time you click this button, you will only get participations that are administrated == false, ie repeat the steps above</p>
        </div>
        <div>
          <button class="btn btn-activate" @click="get3rdPartyList()">Download List</button>
          <button class="btn btn-transparent" @click="show3rdPartyListModal = false">Close</button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="showStatsModal"
      size="xlarge"
      modalType="fullscreen"
      @close="showStatsModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Get Stats' }">
      <slot>
        <h3>Do you want to filter on a store?</h3>
        <md-field>
          <label>Store</label>
          <md-input v-model="participationStore"></md-input>
        </md-field>
        <md-field>
          <label>Top Stores, per store address</label>
          <md-input v-model="participationStoreTop" placeholder="20"></md-input>
        </md-field>
        <div>
          <button class="btn btn-activate" @click="getStats()">Download Stats</button>
          <button class="btn btn-transparent" @click="showStatsModal = false">Close</button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="showCTAModal"
      size="xlarge"
      modalType="fullscreen"
      @close="showCTAModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Get CTA\'s' }">
      <slot>
        <md-field>
          <label for="administrated">won?</label>
          <md-select
            v-model="won">
            <md-option value="all">Both</md-option>
            <md-option :value="true">won == true</md-option>
            <md-option :value="false">won == false</md-option>
          </md-select>
        </md-field>
        <div>
          <button class="btn btn-activate" @click="getUnclaimed()">Download CTA's</button>
          <button class="btn btn-transparent" @click="showCTAModal = false">Close</button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="dateModal"
      size="xlarge"
      modalType="fullscreen"
      @close="dateModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Set filter for created-date' }">
      <slot>
        <md-datepicker
          v-model="createdStart">
          <label>Created Start</label>
        </md-datepicker>
        <md-datepicker
          v-model="createdEnd">
          <label>Created End</label>
        </md-datepicker>
        <div>
          <button class="btn btn-activate" @click="applyDateFilter()">Apply</button>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// import { cloneDeep as _cloneDeep } from 'lodash-es';
import moment from 'moment-timezone';
import { debounce as _debounce } from 'lodash-es';
import InputField from '../InputField/InputField';

export default {
  name: 'ParticipationList',
  components: {
    InputField,
  },
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.findAdminAllParticipations = _debounce(() => {
      this.$store.dispatch(`games/find${this.userType}AllParticipations`, {
        key: this.key,
        search: this.search,
        filters: this.getActiveFilters(),
        gameId: this.gameId,
        createdStart: this.createdStart/1000,
        createdEnd: this.createdEnd/1000 + 3600*24,
        first: this.first,
        number: this.number,
        sort: this.theSort,
      }).then(() => {
        if (this.gameId) {
          this.gameType = this.participations[0].game.game_type;
        }
        this.loadedParticipations = true;
      });
    }, 300);

    this.findAdminGame = _debounce(() => {
      this.$store.dispatch(`games/find${this.userType}Game`, {
        query: this.actSearch,
        entityType: this.gameType,
      }).then(() => {
        this.loadedGames = true;
      });
    }, 300);
  },
  beforeUnmount() {
    this.findAdminAllParticipations.cancel();
    this.findAdminGame.cancel();
  },
  mounted() {
    this.setActiveSearch();
    this.setActiveColumns();
    this.setActiveFilters();
    this.setGameId();
    this.setPagination();

    this.findAdminAllParticipations();
    this.findAdminGame();
  },
  computed: {
    ...mapGetters('games', ['games', 'participations', 'participationsTotal']),
    userType() {
      return this.admin ? 'Admin' : 'Company';
    },
    userApi() {
      return this.admin ? 'admin' : 'c';
    },
    loadedPage() {
      return this.loadedGames && this.loadedParticipations;
    },
    actualColumns() {
      return this.columns.filter((column) => {
        return this.admin ? true : !column.admin;
      });
    },
    activeColumns() {
      return this.columns.filter((column) => {
        return column.active && (this.admin ? true : !column.admin);
      });
    },
    gamePicker() {
      let gamePicker = [];
      if (this.games && this.games.length) {
        for (let i = 0; i < this.games.length; i++) {
          const game = this.games[i];
          gamePicker.push(game);
          if (game._id == this.gameId) {
            gamePicker[gamePicker.length-1].active = true;
          }
        }
      }
      gamePicker.sort(function (a, b) {
        if (a.company.name < b.company.name) {
          return -1;
        }
        if (a.company.name > b.company.name) {
          return 1;
        }
        return 0;
      });
      return gamePicker;
    },
  },
  data() {
    return {
      gameType: 'QrHunt',
      gameTypes: ['QrHunt', 'LoyaltyShop', 'Spin', 'Scratch'],
      loadedParticipations: false,
      loadedGames: false,
      filterOptions: false,
      columnOptions: false,
      gameOptions: false,
      statOptions: false,
      dateModal: false,
      key: '',
      search: '',
      actSearch: '',
      retSearch: '',
      filters: [
        { id: 'datemodal', name: 'Date', active: true,
          func: () => {
            this.dateModal = !this.dateModal;
          }
        },
        { id: 'withcomments', name: 'With comments', active: false, filter: { comments: { $ne: [] } } },
        { id: 'admininsteps', name: 'Admin in steps', active: false, filter: { 'steps.role': { $eq: 'Admin' }}},
        { id: 'adminnotinsteps', name: 'Admin not in steps', active: false, filter: { 'steps.role': { $ne: 'Admin' }}},
      ],
      gameId: '',
      first: 0,
      number: 50,
      columns: [
        { title: 'Buttons', db: 'buttons', active: true, admin: true },
        { title: 'Comp name', active: false, db: 'company.name', type: 'string', admin: true },
        { title: 'Game name', active: true, db: 'game.name', type: 'string' },
        { title: 'Game id', active: false, db: 'game._id', type: 'string', admin: true },
        { title: 'Participation id', active: false, db: '_id', type: 'string' },
        { title: 'Name', active: true, db: 'user.name+user.last_name', type: 'string' },
        { title: 'Phone Nr', active: true, db: 'user.phone_nr', type: 'string' },
        { title: 'Email', active: true, db: 'user.email', type: 'string', edit: true },
        { title: 'Points', active: true, db: 'points.length', type: 'string', edit: false },
        { title: 'Points Balance', active: true, db: 'points_balance', type: 'number', edit: true },
        { title: 'Name at Address', active: false, db: 'name_at_address', type: 'string' },
        { title: 'Address', active: false, db: 'user.address', type: 'string' },
        { title: 'Postal_code', active: false, db: 'user.postal_code', type: 'string' },
        { title: 'City', active: false, db: 'user.city', type: 'string' },
        { title: 'State', active: false, db: 'user.state', type: 'string' },
        { title: 'Country', active: false, db: 'user.country', type: 'string' },
        { title: 'Cta_done', active: true, db: 'cta_done', type: 'boolean' },
        { title: 'Accepted_terms', active: true, type: 'boolean', db: 'accepted_terms' },
        { title: 'Accepted_merchandise', active: true, type: 'boolean', db: 'accepted_merchandise' },
        { title: 'Administrated', active: false, type: 'boolean', db: 'administrated', edit: true, admin: true },
        { title: 'Comments', active: false, type: 'array', db: 'comments', value: 'comment' },
        { title: 'Status', active: false, type: 'string', db: 'status', admin: true },
        { title: 'Running_claim', active: false, type: 'boolean', db: 'running_claim', admin: true },
        { title: 'Created_at', active: true, type: 'unix', db: 'created_at', sort: true },
        { title: 'Updated_at', active: false, type: 'unix', db: 'updated_at', sort: true },
      ],
      theSort: { created_at: -1 },
      activeSort: 'created_at',
      activeSortType: 'descending',
      editId: '',
      editValue: '',
      editKey: '',
      editModal: false,
      showListModal: false,
      show3rdPartyListModal: false,
      ctaDone: 'all',
      statCreatedStart: 1672578061000,
      statCreatedEnd: (new moment()).valueOf(),
      createdStart: 1672578061000,
      createdEnd: (new moment()).valueOf(),
      administrated: 'all',
      setAdministrated: false,
      showStatsModal: false,
      won: 'all',
      showCTAModal: false,
      participationStore: '',
      participationStoreTop: 20,
    };
  },
  watch: {
    search() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      if (!this.search) {
        delete query.search;
      } else {
        query.search = this.search;
      }
      this.$router.replace({ query });
    },
    columns: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.col = [];
        for (let i = 0; i < this.columns.length; i++) {
          const column = this.columns[i];
          if (!column.exclude && column.active) {
            query.col.push(column.db);
          }
        }
        this.$router.replace({ query });
        this.findAdminAllParticipations();
      },
      deep: true
    },
    filters: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.filt = [];
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filter.active && !filter.func) {
            query.filt.push(filter.id);
          }
        }
        this.$router.replace({ query });
        this.resetPagination();
        this.findAdminAllParticipations();
      },
      deep: true
    },
    gameId(newVal, oldVal) {
      if (!newVal && !oldVal) {
        return;
      }
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.game = this.gameId;
      this.$router.replace({ query });
      this.resetPagination();
      this.findAdminAllParticipations();
    },
    first() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.first = this.first;
      this.$router.replace({ query });
      this.findAdminAllParticipations();
    },
  },
  methods: {
    setActiveSearch() {
      const { search } = this.$route.query;
      this.search = search;
    },
    setActiveColumns() {
      const { col } = this.$route.query;
      if (col && col.length) {
        for (let i = 0; i < this.columns.length; i++) {
          const column = this.columns[i];
          if (col.includes(column.db)) {
            column.active = true;
          } else {
            column.active = false;
          }
        }
      }
    },
    setActiveFilters() {
      const { filt } = this.$route.query;
      const { created_at_start, created_at_end } = this.$route.query;
      this.createdStart = created_at_start ? Number(created_at_start) : 1672578061000;
      this.createdEnd = created_at_end ? Number(created_at_end) : (new moment()).valueOf();
      if (filt && filt.length) {
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filt.includes(filter.id)) {
            filter.active = true;
          } else if (filter.id == 'datemodal') {
            filter.active = true;
          } else {
            filter.active = false;
          }
        }
      }
    },
    setPagination() {
      const { first, number } = this.$route.query;
      this.first = first ? Number(first) : 0;
      this.number = number ? Number(number) : 50;
    },
    setGameId() {
      const { game } = this.$route.query;
      this.gameId = game;
    },
    getActiveFilters() {
      let realFilters = {};
      for (let i = 0; i < this.filters.length; i++) {
        const filter = this.filters[i];
        if (filter.active) {
          Object.assign(realFilters, filter.filter);
        }
      }
      return realFilters;
    },
    activateGameFilter(game) {
      for (let i = 0; i < this.gamePicker.length; i++) {
        this.gamePicker[i].active = false;
      }
      if (this.gameId != game._id) {
        game.active = true;
        this.gameId = game._id;
      } else {
        game.active = false;
        this.gameId = '';
      }
    },
    getValue(participation, column) {
      try {
        if (column.type == 'array') {
          let result = [];
          for (let i = 0; i < participation[column.db].length; i++) {
            const element = participation[column.db][i];
            result.push(element[column.value])
          }
          return result.join(' && ');
        }
        const lastValue = this.getLastValue(participation, column.db);
        if (column.type == 'boolean') {
          return lastValue == 'undefined' ? false : lastValue == 'true';
        } else if (column.type == 'number-floor') {
          return Math.floor(Number(lastValue)*1000)/1000;
        } else if (column.type == 'unix') {
          return moment.unix(lastValue).format('YYYY-MM-DD HH:mm:ss');
        } else {
          return lastValue == 'undefined' ? '' : lastValue;
        }
      } catch (error) {
        console.error('getValue', error, participation, column);
      }
    },
    getLastValue(participation, db) {
      const dbSeveralKeys = db.split('+');
      let lastValue = '';
      for (let j = 0; j < dbSeveralKeys.length; j++) {
        const dbSubKey = dbSeveralKeys[j];
        const dbKeys = dbSubKey.split('.');
        try {
          let result = participation[dbKeys[0]];
          if (dbKeys.length > 1) {
            for (let i = 1; i < dbKeys.length; i++) {
              result = result[dbKeys[i]];
            }
          }
          if (typeof result == 'object') {
            result = JSON.stringify(result);
          }
          lastValue += `${result}${db.includes('+') ? ' ': ''}`;
        } catch (error) {
          console.error('getLastValue', error, participation, db, dbSubKey, dbKeys);
        }
      }
      return lastValue || '';
    },
    toggleSort(column) {
      if (column.sort) {
        if (this.activeSort == column.db) {
          this.activeSortType = this.activeSortType == 'ascending' ? 'descending' : 'ascending';
        } else {
          this.activeSort = column.db;
          this.activeSortType = 'ascending';
        }
        this.theSort = {};
        this.theSort[this.activeSort] = this.activeSortType == 'ascending' ? 1 : -1;
        this.findAdminAllParticipations();
      }
    },
    openEditModal(participation, db) {
      this.editId = participation._id;
      this.editValue = participation[db];
      this.editKey = db;
      this.editModal = true;
    },
    updateParticipation() {
      this.loadedParticipations = false;
      this.$store.dispatch(`games/update${this.userType}ParticipationByKey`, {
        entityId: this.editId,
        key: this.editKey,
        value: this.editValue,
      }).then(() => {
        this.editModal = false;
        this.editId = '';
        this.editValue = '';
        this.editKey = '';
        this.loadedParticipations = true;
      });
    },
    resetPagination() {
      console.log('resetPagination');
      this.first = 0;
      this.number = 50;
    },
    paginate(index) {
      this.first = this.first + (this.number*index);
      this.findAdminAllParticipations();
    },
    applyDateFilter() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.created_at_start = this.createdStart;
      query.created_at_end = this.createdEnd;
      this.$router.replace({ query });
      this.findAdminAllParticipations();
    },
    // TODO: add the search in the lists below
    getList() {
      axios.get(`/api/${this.userApi}/games/${this.gameType}/${this.gameId}/participations/csv`
        +`?createdStart=${this.statCreatedStart/1000}&createdEnd=${this.statCreatedEnd/1000}&setAdministrated=${this.setAdministrated}&administrated=${this.administrated}&ctaDone=${this.ctaDone}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.participations[0]?.game.name || this.gameId}_participations_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    get3rdPartyList() {
      axios.get(`/api/${this.userApi}/games/${this.gameType}/${this.gameId}/participations/csv`
        +`?setAdministrated=cta_done&administrated=false&ctaDone=true`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `thirdparty_${this.participations[0]?.game.name || this.gameId}_participations_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getStats() {
      axios.get(`/api/${this.userApi}/games/${this.gameId}/statistics/csv?store=${this.participationStore}&top=${this.participationStoreTop}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.participations[0]?.game.name || this.gameId}_statistics_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getUnclaimed() {
      axios.get(`/api/${this.userApi}/games/${this.gameId}/limited-ctas/csv?won=${this.won}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.participations[0]?.game.name || this.gameId}_unclaimed_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.md-table-row {
  .md-table-cell {
    border: 1px solid #ebebeb;
  }
}
.btn-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
  .btn-small {
    height: 20px;
    font-size: 10px;
    padding: 1px;
  }
}
.pagination {
  background-color: rgba(255, 255, 255, .5);
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 10px 20px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./ParticipationList.scss";
</style>
