<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">

    <EntityEditor
      v-if="loadedPage"
      :title="goodiebag.name"
      entity-type="Goodiebag"
      :entity="goodiebag"
      :attach="attach"
      :admin="true"
      :breadcrumb="goodiebag._id"/>

    <div class="admin-entity--subentity">
      <div class="admin-entity--title">Child Collections</div>
      <div class="admin-goodiebags-wrapper subentity">
        <div
          v-for="childGoodiebag in goodiebag.childGoodiebags"
          :key="childGoodiebag._id"
          class="goodiebag-box"
          :style="`background-image: url(${childGoodiebag.media ? childGoodiebag.media.url : ''})`">
          <div
            class="goodiebag-live-status"
            :class="{
              'goodiebag-live': childGoodiebag.public,
              'goodiebag-offline': !childGoodiebag.public,
            }"></div>
          <div class="goodiebag-box-text">
            <div class="goodiebag-box-name">{{ childGoodiebag.name }}</div>
            <div
              v-if="childGoodiebag.childGoodiebags && childGoodiebag.childGoodiebags.length"
              class="goodiebag-box-info">
              {{ childGoodiebag.childGoodiebags.length }} {{ childGoodiebag.childGoodiebags.length === 1 ? 'collection' : 'collections' }}
            </div>
            <div
              v-if="childGoodiebag.offers && childGoodiebag.offers.length"
              class="goodiebag-box-info">
              {{ childGoodiebag.offers.length }} {{ childGoodiebag.offers.length === 1 ? 'aktivering' : 'aktiveringar' }}
            </div>
            <div
              class="goodiebag-box-fullscreen fullscreen-icon preview-action"
              @click="getGoodiebag(childGoodiebag._id)">
              <iconComponent symbol="fullscreen"/>
            </div>
            <div
              class="goodiebag-box-edit preview-action"
              @click="$router.push({ name: 'AdminGoodiebag', params: { goodiebagId: childGoodiebag._id } })">
              <iconComponent symbol="pencil"/>
            </div>
          </div>
        </div>
        <div
          class="goodiebag-box goodiebag-box-add"
          @click="showGoodiebagDialog = true">
          <iconComponent symbol="circledPlus"/>
        </div>
      </div>
      <div class="admin-entity--title">Offers</div>
      <div class="admin-offer-wrapper subentity">
        <div
          class="offer-box"
          v-for="offer in goodiebag.offers"
          :key="offer._id">
          <div
            v-if="offer.thumbnail && offer.thumbnail.fileType && offer.thumbnail.fileType.includes('image')"
            class="offer-pic"
            :style="`background-image: url(${offer.thumbnail.url});`">
          </div>
          <div
            v-else-if="offer.media && offer.media[0] && offer.media[0].fileType.includes('image')"
            class="offer-pic"
            :style="`background-image: url(${offer.media && offer.media[0] ? offer.media[0].url : '' });`">
          </div>
          <video
            v-else-if="offer.media && offer.media[0] && offer.media[0].fileType.includes('video')"
            class="news-autoplay autoplayMedia"
            :controls="false"
            muted playsinline>
            <source
              :src="`${offer.media && offer.media[0] ? offer.media[0].url : '' }`"
              :type="offer.media[0].fileType">
            {{ transl.Offer.novideo }}
          </video>

          <img
            v-if="offer.icon?.url && offer.unique_icon"
            class="offer-icon"
            :src="`${offer.icon?.url}`"/>
          <div class="card-tag">
            <md-chip
              v-if="offer.offer_tag"
              class="card-chip">
            {{ transl.tags[offer.offer_tag] }}
            </md-chip>
          </div>

          <div
            class="offer-box-text">
            <div
              class="goodiebag-box-fullscreen fullscreen-icon preview-action"
              @click="getOffer(offer._id)">
              <iconComponent symbol="fullscreen"/>
            </div>
            <div
              class="goodiebag-box-edit preview-action"
              @click="$router.push({ name: 'AdminOffer', params: { offerId: offer._id } })">
              <iconComponent symbol="pencil"/>
            </div>
            <div class="offer-box-name body-1-bold">{{ offer.first_header }}</div>
            <div
              v-if="offer.variations"
              class="offer-box-info body-2">
              <iconComponent symbol="style"/>
              <span>Fler varianter</span>
            </div>
            <div
              v-else-if="offer.save_text"
              class="offer-box-info body-2">
              <iconComponent symbol="newReleases"/>
              <span class="md-16-icon">{{ offer.save_text }}</span>
            </div>
            <div
              v-else
              class="offer-box-info body-2">
              <span style="padding: 0;">{{ offer.quick_text }}</span>
            </div>
          </div>
        </div>
        <div
          class="offer-box goodiebag-box-add"
          @click="showOfferDialog = true">
          <iconComponent symbol="circledPlus"/>
        </div>
      </div>

      <PreviewGoodiebag
        v-if="previewGoodiebag && previewGoodiebag._id && showPreviewGoodiebagModal"
        :goodiebag="previewGoodiebag"
        @close="showPreviewGoodiebagModal = false"/>
      <PreviewOffer
        v-if="previewOffer && previewOffer._id && showPreviewOfferModal"
        :offer="previewOffer"
        :goodiebag="goodiebag"
        @close="showPreviewOfferModal = false"/>

      <modal v-if="showGoodiebagDialog" size="xlarge"
            @close="showGoodiebagDialog = false;" modalType="fullscreen">
        <slot>
          <EntityEditor
            v-if="loadedPage"
            entity-type="Goodiebag"
            :attach="attachGoodiebag"
            :admin="true"/>
        </slot>
      </modal>

      <modal v-if="showOfferDialog" size="xlarge"
            @close="showOfferDialog = false;" modalType="fullscreen">
        <slot>
          <EntityEditor
            v-if="loadedPage"
            entity-type="Offer"
            :attach="attachOffer"
            :admin="true"/>
        </slot>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import offerApi from '../api/offer.api';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import goodiebagApi from '../api/goodiebag.api';
import PreviewGoodiebag from "../components/PreviewGoodiebag";
import PreviewOffer from "../components/PreviewOffer";

export default {
  name: 'AdminGoodiebag',
  components: {
    EntityEditor,
    PreviewGoodiebag,
    PreviewOffer,
  },
  props: ['goodiebagId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
  },
    destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters(["goodiebag", "goodiebags", "userStatus"]),
  },
  watch: {
    goodiebagId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    }
  },
  data() {
    return {
      loadedPage: false,
      search: '',
      showGoodiebagDialog: false,
      showOfferDialog: false,
      showPreviewOfferModal: false,
      showPreviewGoodiebagModal: false,
      previewGoodiebag: {},
      previewOffer: {},
      attach: [],
      attachGoodiebag: [],
      attachOffer: [],
    };
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('getAdminGoodiebag', { entityId: this.goodiebagId }).then(() => {
        this.setAttachments();
        this.setPageTitle('Admin', 'Collection');
      });
    },
    setAttachments() {
      if (this.goodiebag.parentGoodiebag && this.goodiebag.parentGoodiebag._id) {
        this.attach.push({
          key: 'goodiebag',
          value: this.goodiebag.parentGoodiebag,
        });
      }

      this.attachGoodiebag = [
        {
          key: 'parentGoodiebag',
          value: {
            text: this.goodiebag.name,
            value: this.goodiebag._id,
          },
        },
        {
          key: 'company',
          value: {
            text: this.goodiebag.company.name,
            value: this.goodiebag.company._id,
          },
        }
      ];
      this.attachOffer = [
        {
          key: 'goodiebag',
          value: {
            text: this.goodiebag.name,
            value: this.goodiebag._id,
          },
        },
        {
          key: 'company',
          value: {
            text: this.goodiebag.company.name,
            value: this.goodiebag.company._id,
          },
        }
      ];
      this.loadedPage = true;
    },
    getOffer(entityId) {
      offerApi.getAdminOffer({ entityId }).then((data) => {
        this.previewOffer = data.offer;
        this.showPreviewOfferModal = true;
      });
    },
    getGoodiebag(entityId) {
      goodiebagApi.getAdminGoodiebag({ entityId }).then((data) => {
        this.previewGoodiebag = data.goodiebag;
        this.showPreviewGoodiebagModal = true;
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminGoodiebag";
</style>
