<template>
  <div
    v-if="loadedRows"
    class="row-list">

    <div
      class="the-list">
      <md-table>
        <md-table-toolbar class="list-toolbar">
          <div>
            <h1 class="md-title">{{ orderTranslations.length }} rows</h1>
          </div>
          <div
            class="column-picker"
            @focus="columnOptions = true"
            @blur="columnOptions = false">
            <button
              class="btn btn-activate"
              @click="columnOptions = !columnOptions">
              Columns
            </button>
            <div
              v-show="columnOptions"
              class="column-options">
              <div
                class="column-option"
                v-for="column in columns"
                :class="{ 'active-column-option': column.active }"
                :key="`column-option-${column.title}`"
                @click="column.active = !column.active">
                {{ column.title }}
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="translationGroupOptions = true"
            @blur="translationGroupOptions = false">
            <button
              class="btn btn-activate"
              @click="translationGroupOptions = !translationGroupOptions">
              Groups
            </button>
            <div
              v-show="translationGroupOptions"
              class="column-options">
              <div
                class="column-option"
                v-for="group in translationGroups"
                :key="`column-option-${group.title}`"
                @click="translationGroup = group.title">
                {{ group.title }}
              </div>
            </div>
          </div>
          <button
            class="btn btn-activate"
            @click="updateModal = true">
            Save
          </button>
          <button
            class="btn btn-activate"
            @click="newRowModal = true">
            New row
          </button>
        </md-table-toolbar>
        <!--style="position: sticky; top: 0; z-index: 1;" dosnt work..-->
        <md-table-row>
          <md-table-head
            v-for="column in activeColumns"
            :key="`title-${column.title}`">
            {{ column.title }}
          </md-table-head>
        </md-table-row>
        <md-table-row
          v-for="(row, index) in filteredTranslations"
          :key="`${row.key}${index}`">
          <md-table-cell
            v-for="column in activeColumns"
            :key="`row-${column.title}`">
            <div>
              <inputField
                v-if="column.edit"
                v-model="row[column.db]"
                @input="editTranslation(row.key, column.db, $event)"/>
              <div v-else>{{ row[column.db] }}</div>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>

    <modal
      v-if="updateModal"
      size="xlarge"
      modalType="fullscreen"
      @close="updateModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Save changes' }">
      <slot>
        <div>
          <InputField
            v-model="translateRest"
            label="Translate all the empty fields from the english column?"
            inputType="checkbox"/>
        </div>
        <div>
          <button
            class="btn btn-activate"
            @click="updateRows()">
            Save
          </button>
          <button
            class="btn btn-transparent"
            @click="updateModal = false">
            Close
          </button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="newRowModal"
      size="xlarge"
      modalType="fullscreen"
      @close="newRowModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'New row' }">
      <slot>
        <div>
          <InputField
            v-model="newRowKey"
            @enterKey="addRow()"/>
        </div>
        <div>
          <button
            class="btn btn-activate"
            @click="addRow()">
            Add
          </button>
          <button
            class="btn btn-transparent"
            @click="newRowModal = false">
            Close
          </button>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputField from '../stories/components/InputField/InputField';

export default {
  name: "AdminTranslations",
  components: {
    InputField,
  },
  props: {
    admin: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.setActiveSearch();
    this.setActiveColumns();
    this.setActiveGroups();

    if (!this.admin) {
      this.setAlert('This page is not available for companies');
      this.$router.go(-1);
      return;
    }

    this.$store.dispatch(`get${this.userType}Translations`, {
      search: this.search,
    }).then(() => {
      this.loadedRows = true;
      this.filterTranslations();
    });
  },
  computed: {
    ...mapGetters(['translations']),
    userType() {
      return this.admin ? 'Admin' : 'Company';
    },
    userApi() {
      return this.admin ? 'admin' : 'c';
    },
    activeColumns() {
      return this.columns.filter((column) => {
        return column.active;
      });
    },
    orderTranslations() {
      const result = [];
      const translations = this.translations;
      const el = this;

      // Helper function to recursively process the translations object
      function processTranslations(obj, lang, prefix = '') {
        for (const key in obj) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            if (prefix == '' && el.translationGroups.findIndex(group => group.title == key) == -1) {
              el.translationGroups.push({ title: key, active: false });
            }
            processTranslations(obj[key], lang, `${prefix}${key}.`);
          } else {
            const fullKey = `${prefix}${key}`;
            const existingEntry = result.find(entry => entry.key === fullKey);
            if (existingEntry) {
              existingEntry[lang] = obj[key];
            } else {
              const newEntry = { key: fullKey };
              newEntry[lang] = obj[key];
              result.push(newEntry);
            }
          }
        }
      }

      // Process each language in the translations object
      for (const lang in translations) {
        processTranslations(translations[lang], lang, '');
      }

      return result;
    },
  },
  data() {
    return {
      loadedRows: false,
      filteredTranslations: [],
      editTranslations: {},
      translateRest: false,
      columnOptions: false,
      translationGroupOptions: false,
      key: '',
      search: '',
      translationGroups: [],
      translationGroup: 'App',
      columns: [
        { title: 'Key', db: 'key', active: true, },
        { title: 'Swedish', db: 'SE', active: true, edit: true },
        { title: 'English', db: 'EN', active: true, edit: true },
        { title: 'Norwegian', db: 'NO', active: true, edit: true },
        { title: 'Danish', db: 'DK', active: true, edit: true },
        { title: 'Finnish', db: 'FI', active: true, edit: true },
        { title: 'Spanish', db: 'ES', active: false, edit: true },
        { title: 'Italian', db: 'IT', active: false, edit: true },
        { title: 'Portuguese', db: 'PT', active: false, edit: true },
        { title: 'French (same for Belgium)', db: 'FR', active: false, edit: true },
        { title: 'Dutch (same for Belgium)', db: 'NL', active: false, edit: true },
        { title: 'Flemish', db: 'FL', active: false, edit: true },
        { title: 'Greek', db: 'GR', active: false, edit: true },
        { title: 'Polish', db: 'PL', active: false, edit: true },
        { title: 'German', db: 'DE', active: false, edit: true },
        { title: 'Czech', db: 'CZ', active: false, edit: true },
        /*{ title: 'Belgium Dutch', db: 'BE_NL', active: false, edit: true },
        { title: 'Belgium French', db: 'BE_FR', active: false, edit: true },*/
        { title: 'Swiss German', db: 'CH_DE', active: false, edit: true },
        { title: 'Swiss French', db: 'CH_FR', active: false, edit: true },
        { title: 'Turkish', db: 'TR', active: false, edit: true },
        { title: 'Croatian', db: 'HR', active: false, edit: true },
        { title: 'Slovenian', db: 'SI', active: false, edit: true },
        { title: 'Estonian', db: 'EE', active: false, edit: true },
        { title: 'Lithuanian', db: 'LT', active: false, edit: true },
        { title: 'Latvian', db: 'LV', active: false, edit: true },
      ],
      updateModal: false,
      newRowKey: '',
      newRowModal: false,
    };
  },
  watch: {
    search() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      if (!this.search) {
        delete query.search;
      } else {
        query.search = this.search;
      }
      this.$router.replace({ query });
    },
    columns: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.col = [];
        for (let i = 0; i < this.columns.length; i++) {
          const column = this.columns[i];
          if (!column.exclude && column.active) {
            query.col.push(column.db);
          }
        }
        this.$router.replace({ query });
      },
      deep: true
    },
    translationGroup() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      if (!this.translationGroup) {
        delete query.group;
      } else {
        query.group = this.translationGroup;
      }

      this.newRowKey = `${this.translationGroup}.`;
      this.filterTranslations();      
      this.$router.replace({ query });
    },
  },
  methods: {
    addRow() {
      if (!/^[^.]+\.[^.]+$/.test(this.newRowKey)) {
        this.setAlert('Key must contain a group and a cell-key');
        return;
      }
      for (let i = 0; i < this.filteredTranslations.length; i++) {
        const translationRow = this.filteredTranslations[i];
        if (translationRow.key === this.newRowKey) {
          this.setAlert('Key already exists');
          return;
        }
      }

      this.filteredTranslations.push({ key: this.newRowKey });
      this.newRowModal = false;
    },
    filterTranslations() {
      this.filteredTranslations = this.orderTranslations.filter(row => {
        const [group] = row.key.split('.');
        return group === this.translationGroup;
      });
    },
    editTranslation(key, db, value) {
      this.editTranslations[`${db}.${key}`] = value;
    },
    setActiveSearch() {
      const { search } = this.$route.query;
      this.search = search;
    },
    setActiveGroups() {
      const { group } = this.$route.query;
      if (group) {
        this.translationGroup = group;
      }
    },
    setActiveColumns() {
      const { col } = this.$route.query;
      if (col && col.length) {
        for (let i = 0; i < this.columns.length; i++) {
          const column = this.columns[i];
          if (col.includes(column.db)) {
            column.active = true;
          } else {
            column.active = false;
          }
        }
      }
    },
    openEditModal(row, db) {
      this.editValue = row[db];
      this.editKey = `${db}.${row.key}`;
      this.editModal = true;
    },
    updateRows() {
      this.loadedRows = false;
      this.$store.dispatch(`update${this.userType}TranslationsByKey`, {
        changes: this.editTranslations,
        translateRest: this.translateRest,
      }).then(() => {
        this.loadedRows = true;
        this.setAlert('Translations successfully updated!');
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.md-table-row {
  .md-table-cell {
    border: 1px solid #ebebeb;
  }
}
.btn-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
  .btn-small {
    height: 20px;
    font-size: 10px;
    padding: 1px;
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminTranslations.scss";
</style>
